<template>
  <!-- <div > -->

  <div class="modal">
    <svg class="lightbox-close" @click="close">
      <use xlink:href="/assets/img/sprite.svg#close"></use>
    </svg>

    <div class="lightbox-wrap" v-if="images && images.length > 1">
      <!-- <swiper ref="lightbox" @slide-change-end="sliding" customize-nav=".lightbox__nav" class=lightbox>
          <SwiperSlide class="lightbox__slide" v-for="(image, index) in images" :key="index">
            <figure><img :src="image"></figure>
          </SwiperSlide>
        </swiper> -->

      <SwiperBox :images="images" />

      <!-- <div class="lightbox__nav">
        <span v-for="(image, index) in images" :key="index" :index="index + 1"
          :style="{ 'background-image': 'url(' + imageUrl(image) + ')' }">
        </span>
      </div> -->
      <!-- <span @click="swiper.slideNext()" class="lightbox-btn lightbox-btn--prev"
        :class="{ 'disabled': !prevExist }">PREV</span>
      <span @click="swiper.slideNext()" class="lightbox-btn" :class="{ 'disabled': !nextExist }">NEXT</span> -->
    </div>

    <figure class="lightbox-wrap" v-else-if="images && images.length === 1">
      <img :src="imageUrl(images[0])">
    </figure>
  </div>

  <!-- </div> -->
</template>

<script>
import { closeModal } from 'jenesius-vue-modal'

import SwiperBox from './SwiperBox.vue'

export default {

  props: ['images'],

  data() {
    return {
      prevExist: false,
      nextExist: true,
    }
  },

  components: {
    SwiperBox,
  },

  methods: {

    imageUrl(image) {
      return image.startsWith('http') ? image : 'https://st1.livecaster.io/lc1/' + image;
    },

    beforeOpen() {
      // headerHandler();
      // this.images = event.params.images;
    },

    // prev() {
    //   this.$refs.lightbox.prev();
    // },

    // next() {
    //   this.$refs.lightbox.next();
    // },

    close() {
      closeModal();
    },

    reset() {
      // headerHandler();
      // this.images = [];
      this.prevExist = false;
      this.nextExist = true;
    },

    sliding(slideIndex) {
      if (slideIndex === 1) {
        this.prevExist = false;
        this.nextExist = true;
      }
      if (slideIndex > 1) {
        this.prevExist = true;
        this.nextExist = true;
      }
      if (slideIndex === this.images.length) {
        this.prevExist = true;
        this.nextExist = false;
      }
    },
  },

}
</script>

<style>
.modal {
  background: transparent;
}

.lightbox-close {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 25px;
  height: 25px;
  padding: 5px;
  background: #000;
  fill: #fff;
  border-radius: 50%;
  cursor: pointer;
  z-index: 1;
}

.lightbox-wrap {
  position: relative;
  height: 560px;
  max-width: 800px;
  padding-bottom: 60px;
}

figure.lightbox-wrap {
  height: auto;
  padding-bottom: 0;
}

.lightbox__slide figure {
  position: relative;
  text-align: center;
}

.lightbox__slide figure figcaption {
  position: absolute;
  top: 100%;
  max-width: 500px;
  margin-top: 10px;
  color: #fff;
  font: 400 1.4rem/1.6rem 'Ubuntu', sans-serif;
  text-align: left;
}

.lightbox__slide figure img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.lightbox__nav {
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: absolute;
  right: 10px;
  top: calc(50% - 30px);
  transform: translateY(-50%);
  max-height: calc(100% - 60px);
  width: 40px;
  z-index: 1;
}

.lightbox__nav span {
  margin-bottom: 10px;
  width: 40px;
  height: 40px;
  opacity: 0.4;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  cursor: pointer;
}

.lightbox__nav span.active {
  opacity: 1;
}

.lightbox__nav span:last-child {
  margin-bottom: 0;
}

.lightbox-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 15px 20px;
  color: #fff;
  background: #313794;
  border: 1px solid #fff;
  cursor: pointer;
  font: 600 1.4rem/2rem 'Ubuntu', sans-serif;
}

.lightbox-btn.disabled {
  opacity: 0.4;
  pointer-events: none;
}

.lightbox-btn--prev {
  right: 90px;
}

@media only screen and (max-width: 600px) {
  .lightbox-wrap {
    max-width: 100vw;
    height: 450px;
  }

  .lightbox__nav {
    right: 0;
    width: 30px;
  }

  .lightbox__nav span {
    width: 30px;
    height: 30px;
  }
}
</style>