<template>
  <form action="post" class="feed-form" @submit.prevent.stop="submit">
    <span v-if="false" class="feed-form__login">Sign in to leave your comment</span>

    <div v-if="!permission" class="feed-form__disabled">
      <svg>
        <use xlink:href="/assets/img/sprite.svg#disable-comments"></use>
      </svg>
      The administrator disabled the comments for this LIVE session
    </div>

    <div v-if="permission" class="feed-form__input">
      <span v-if="username" class="feed-form__username">{{ username }}</span>
      <input v-if="!username" type="text" v-model.trim="name" placeholder="Enter your name and press enter">
      <input v-if="username" type="text" v-model.trim="message" placeholder="Enter your message">
      <button :class="{ disabled: disableSubmit }">
        <svg v-if="username">
          <use xlink:href="/assets/img/sprite.svg#send"></use>
        </svg>
        <span v-if="!username">CREATE NAME</span>
        <span v-if="username">SEND</span>
      </button>
    </div>
  </form>
</template>

<script>
import { mapState } from 'vuex';

export default {

  data() {
    return {
      name: '',
      message: '',
    }
  },

  methods: {
    submit() {
      if (this.name.length && !this.username) {
        this.$store.dispatch('frontStream/setUsername', this.name);
      }
      else if (this.message.length) {
        this.$store.dispatch('frontStream/sendMessage', this.message);
        this.message = '';
      }
    },
  },

  computed: {
    ...mapState({
      permission(state) {
        return state.frontStream && state.frontStream.info.userMessages && state.frontStream.info.status == 1;
      },
      username(state) {
        return state.frontStream.username;
      },
    }),

    disableSubmit() {
      if (!this.name.length) {
        return true;
      }
      else if (!this.message.length && this.username) {
        return true;
      }
      else {
        return false;
      }
    },
  },

}
</script>

<style scoped>
.feed-form {
  position: relative;
  display: flex;
  height: 40px;
  background: #fff;
}

.feed-form__login {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: var(--blue);
  color: #fff;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: bold;
  cursor: pointer;
}

.feed-form__disabled {
  position: absolute;
  display: flex;
  align-items: center;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  text-align: center;
  font-size: 1.6rem;
  line-height: 1.8rem;
  font-weight: 500;
  color: var(--light-gray);
}

.feed-form__disabled svg {
  margin-right: 10px;
  padding: 10px;
  width: 80px;
  height: 40px;
  fill: #fff;
  background: linear-gradient(to bottom, var(--fuchsia), var(--orange));
}

.feed-form__input {
  display: flex;
  align-items: center;
  width: 100%;
}

.feed-form__username {
  max-width: 100px;
  padding-left: 10px;
  font-size: 1.4rem;
  line-height: 2.2rem;
  font-weight: bold;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--blue);
}

.feed-form__input input {
  display: block;
  height: 100%;
  margin: 0 10px;
  flex: 1;
  outline: none;
  border: none;
  font: 500 1.4rem/2rem 'Ubuntu', sans-serif;
}

button {
  display: flex;
  align-items: center;
  justify-content: center;
  padding-right: 20px;
  margin-left: auto;
  height: 100%;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  font: bold 1.4rem/1.6rem 'Ubuntu', sans-serif;
  color: var(--blue);
}

button svg {
  margin-right: 5px;
  width: 20px;
  height: 17px;
  fill: var(--blue);
}

button.disabled {
  pointer-events: none;
  cursor: default;
  color: var(--light-gray);
}

button.disabled svg {
  fill: var(--light-gray);
}
</style>
