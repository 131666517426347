<template>
  <section class="feed__main">
    <FeedActions :streamTab="streamTab" :streamFilters="streamFilters" @filterMessages="filterMessages" />

    <div class="feed__stream">
      <FeedEmpty v-if="!messages.length" />

      <!-- <Match v-for="(msg, index) in testMatchbubbles" :key="index" :msg="msg">
        <Actions :id="msg.id" :highlight="msg.highlight" />
      </Match> -->

      <div v-for="msg in messages" :key="msg.id">
        <!-- <Match v-if="msgIsMatch(msg)" :msg="msg">
          <Actions :id="msg.id" :highlight="msg.highlight" />
        </Match> -->

        <HostBubble :msg="msg" v-if="!msg.guest">
          <BubbleActions :eventId="eventId" :id="msg.id" :highlight="msg.highlight"
            v-if="event.status === 1 && msg.status === 1" />
        </HostBubble>

        <GuestBubble :msg="msg" v-if="msg.guest">
          <BubbleActions :eventId="eventId" :id="msg.id" :highlight="msg.highlight"
            v-if="event.status === 1 && msg.status === 1" />
        </GuestBubble>

      </div>

      <!-- <div ref="trigger"></div> -->

    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex'
import FeedActions from '@/components/misc/FeedActions';
import HostBubble from '@/components/misc/bubbles/main/HostBubble'
import GuestBubble from '@/components/misc/bubbles/main/GuestBubble'
// import Match from '../../../components/bubbles/match/Match'
import BubbleActions from '@/components/misc/feed_main/bubble_actions/BubbleActions'
import FeedEmpty from '@/components/misc/feed_empty/FeedEmpty'

export default {

  props: ['eventId'],

  data() {
    return {
      testMatchbubbles: [
        {
          msgType: 'match',
          side: 'home',
          type: 'goal',
          player: 'Αργύρης Καμπέτσης',
          minute: 45,
        },
        {
          msgType: 'match',
          side: 'home',
          type: 'card',
          card: 1,
          player: 'Αργύρης Καμπέτσης',
          minute: 80,
        },
        {
          msgType: 'match',
          side: 'home',
          type: 'card',
          card: 2,
          player: 'Αργύρης Καμπέτσης',
          minute: 2,
        },
        {
          msgType: 'match',
          side: 'home',
          type: 'card',
          card: 3,
          player: 'Αργύρης Καμπέτσης',
          minute: 25,
        },
        {
          msgType: 'match',
          side: 'away',
          type: 'change',
          player_in: 'Αργύρης Καμπέτσης',
          player_out: 'Δημητρής Κολοβός',
          minute: 90,
        }
      ],
    }
  },

  // async mounted() {
  //   const options = {
  //     root: null,
  //     rootMargin: '0px 0px 10px',
  //     threshold: '0'
  //   };

  //   const observer = new IntersectionObserver(this.handleIntersection, options);
  //   observer.observe(this.$refs.trigger);
  // },

  // beforeUnmount() {
  //   if (this.observer) {
  //     this.observer.disconnect();
  //   }
  // },

  methods: {
    filterMessages(term) {
      this.$store.dispatch('stream/filterMessages', term);
    },

    // handleIntersection(entries) {
    //   const entry = entries[0];
    //   if (entry.isIntersecting) {
    //     this.$store.dispatch('stream/fetchMessages', { eventId: this.eventId, nextPage: true });
    //   }
    // },
  },

  computed: {
    ...mapGetters('stream', {
      event: 'event',
      streamTab: 'activeStreamTab',
      streamFilters: 'streamFilterFlags',
      liveMessages: 'liveMessages',
      ignoredMessages: 'ignoredMessages',
      blockedMessages: 'blockedMessages',
    }),

    messages() {
      let output = [];
      switch (this.streamTab) {
        case 'ignored':
          output = this.ignoredMessages;
          break;
        case 'blocked':
          output = this.blockedMessages;
          break;
        default:
          output = this.liveMessages;
          break;
      }
      return output;
    },
  },

  components: {
    FeedActions,
    GuestBubble,
    HostBubble,
    BubbleActions,
    // Match,
    // LoadMore,
    FeedEmpty,
  },

}
</script>

<style>
.feed__main {
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  max-height: 700px;
}

.feed__stream {
  padding: 15px 20px;
  flex: 1;
  overflow-y: auto;
  background: var(--pale-gray);
  color: #000;
}

.feed__stream::-webkit-scrollbar {
  width: 4px;
}

.feed__stream::-webkit-scrollbar-thumb {
  background: var(--blue);
}

/* User Colors */
.turquoise {
  background: var(--bubble-turquoise);
}

.pink {
  background: var(--bubble-pink);
}

.orange {
  background: var(--bubble-orange);
}

.blue {
  background: var(--bubble-blue);
}

.grey {
  background: var(--bubble-grey);
}

.dark-grey {
  background: var(--bubble-dark-grey);
}

/* Bubbles */
.bubble {
  display: flex;
  align-items: flex-start;
  margin: 0 0 15px;
}

.bubble--user {
  flex-direction: row-reverse;
  text-align: right;
}

.bubble__icon {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  font-size: 2rem;
  line-height: 3rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  overflow: hidden;
}

.bubble__core {
  max-width: calc(100% - 200px);
}

.bubble__core--admin {
  margin-left: 12px;
}

.bubble__core--user {
  margin-right: 12px;
}

.bubble__core--enclose {
  max-width: 380px;
  flex: 1;
}

.bubble__core--match {
  max-width: 350px;
  flex: 1;
}

.bubble__info {
  display: flex;
  justify-content: space-between;
}

.bubble__name {
  display: inline-block;
  max-width: 200px;
  vertical-align: middle;
  margin-right: 20px;
  font-size: 1.5rem;
  line-height: 2.1rem;
  font-weight: 500;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  color: #2e2e2e;
}

.bubble__time {
  display: inline-block;
  vertical-align: middle;
  font-size: 1.2rem;
  line-height: 2.1rem;
  color: #696969;
}

.bubble__message {
  position: relative;
  margin-top: 5px;
  padding: 0.1px;
  border-radius: 10px;
  color: #000;
  background: #fff;
  font: 400 1.4rem/2rem 'Ubuntu', sans-serif;
}

.bubble__message--user {
  border-top-right-radius: 0;
}

.bubble__message--admin {
  border-top-left-radius: 0;
  box-shadow: 3px 3px 0 var(--blue);
}

.bubble__message--reply {
  margin-top: -5px;
  border-radius: 0 0 10px 10px;
  background: var(--blue);
  color: #fff;
}

.bubble__message--highlight {
  box-shadow: 3px 3px 0 #ed640f;
}

.bubble__highlight {
  position: absolute;
  top: 50%;
  left: 0;
  width: 15px;
  height: 15px;
  transform: translate(-50%, -50%);
}

/* Message */
.bubble__text {
  padding: 15px 20px;
  word-break: break-word;
  text-align: left;
}

.bubble__text>* {
  margin-bottom: 10px;
}

.bubble__text>*:last-child {
  margin-bottom: 0;
}

.bubble__text blockquote {
  position: relative;
  padding: 15px 0 0 20px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  z-index: 1;
}

.bubble__text blockquote:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 25px;
  background-image: url(@/assets/img/quotes.svg);
  z-index: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bubble__text b,
.bubble__text strong {
  font-weight: bold;
}

.bubble__text ul,
.bubble__text ol {
  margin-left: 20px;
  list-style-type: none;
}

.bubble__text ol {
  counter-reset: ordered_item;
}

.bubble__text li {
  position: relative;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.bubble__text ul>li:after {
  content: '';
  position: absolute;
  top: 10px;
  left: -15px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--blue);
}

.bubble__text ol>li {
  counter-increment: ordered_item;
}

.bubble__text ol>li:after {
  content: counter(ordered_item)".";
  position: absolute;
  left: -20px;
  top: 0;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: var(--blue);
}

.bubble__text a {
  text-decoration: underline;
  font-weight: 500;
  color: var(--blue);
}

.bubble__core--enclose .bubble__text {
  border-bottom: 1px solid var(--blue);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

/* Embed */
.bubble__embed {
  display: flex;
  position: relative;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
}

.bubble__embed>* {
  margin-bottom: 10px;
}

.bubble__embed>*:last-child {
  margin-bottom: 0;
}

.bubble__embed-indicator {
  position: absolute;
  padding: 7px;
  margin-bottom: 0;
  right: 100%;
  bottom: 20px;
  width: 30px;
  height: 30px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: var(--blue);
  fill: #fff;
}

.bubble__embed-image {
  position: relative;
}

.bubble__embed-image img {
  object-fit: contain;
  width: 100%;
}

.bubble__embed-title,
.bubble__embed-description {
  font: 500 1.4rem/1.8rem 'Ubuntu', sans-serif;
}

.bubble__embed--link {
  min-height: 100px;
  padding-left: 140px;
}

.bubble__embed--no-img {
  padding-left: 20px;
}

.bubble__embed--link .bubble__embed-image {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100px;
  height: 60px;
  margin-bottom: 0;
}

.bubble__embed--link .bubble__embed-image img {
  height: 100%;
}

.bubble__embed--link .bubble__embed-title {
  margin-bottom: 5px;
  font: 400 1rem/1.6rem 'Ubuntu', sans-serif;
  color: #6a6a6a;
}

.bubble__text+.bubble__embed {
  background: linear-gradient(to bottom, rgba(11, 52, 217, 0.1), transparent);
}

.bubble__text+.bubble__embed--video {
  padding-top: 0;
}

/* Assets */
.bubble__assets {
  position: relative;
  margin: 20px;
  cursor: pointer;
}

.bubble__assets img {
  object-fit: cover;
  width: 100%;
}

.bubble__assets span {
  position: absolute;
  padding: 7px 10px;
  bottom: 0;
  right: 0;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 500;
  color: #fff;
  background: var(--blue);
}

.bubble__text+.bubble__assets {
  margin-top: 0;
}

/* Match */
.bubble__match {
  position: relative;
  margin-top: 5px;
  border-radius: 10px;
  color: #000;
  background: #fff;
  font: 400 1.4rem/2rem 'Ubuntu', sans-serif;
  box-shadow: 3px 3px 0 var(--blue);
}

.bubble__match-head {
  display: flex;
  align-items: center;
  padding: 5px 20px;
  word-break: break-word;
  text-align: left;
  font-size: 1.3rem;
  line-height: 1.7rem;
  color: var(--gray);
}

.bubble__match-head figure {
  width: 25px;
  height: 25px;
  margin-right: 5px;
  border-radius: 50%;
  overflow: hidden;
}

.bubble__match-head span {
  margin-left: auto;
  font-size: 1.5rem;
}

.bubble__match-content {
  display: flex;
  align-items: center;
  padding: 15px 20px;
  background: linear-gradient(to right, rgba(11, 52, 217, 0), rgba(11, 52, 217, 0.1));
}

.bubble__match-player {
  margin-left: 20px;
  font-size: 1.3rem;
  line-height: 1.7rem;
  font-weight: normal;
}

.bubble__match-content--goal {
  padding-left: 0;
  font-size: 2.2rem;
  line-height: 3rem;
  font-weight: bold;
}

.bubble__match-content--goal svg {
  width: 40px;
  height: 40px;
  margin-right: 10px;
}

.bubble__match-card {
  width: 20px;
  height: 30px;
  border-radius: 4px;
}

.bubble__match-card--yellow {
  background: var(--yellow);
}

.bubble__match-card--red {
  background: var(--red);
}

.bubble__match-card--both {
  position: relative;
  top: -3px;
  width: 20px;
  height: 30px;
  margin-right: 10px;
  border-radius: 4px;
  background: var(--red);
}

.bubble__match-card--both:before {
  content: '';
  position: absolute;
  top: 6px;
  left: 10px;
  width: 100%;
  height: 100%;
  border-radius: 4px;
  background: var(--yellow);
}

.bubble__match-content--change {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.bubble__match-content--change div:not(:last-child) {
  margin-bottom: 10px;
}

.bubble__match-content--change div:nth-child(1) svg {
  fill: var(--red);
}

.bubble__match-content--change div:nth-child(2) svg {
  fill: #009d06;
  transform: scaleX(-1);
}

.bubble__match-content--change svg {
  width: 16px;
  height: 8px;
}

.bubble__match-content--change .bubble__match-player {
  margin-left: 10px;
}

@media only screen and (max-width: 680px) {
  .bubble__core--enclose {
    max-width: calc(100% - 200px);
  }
}

@media only screen and (max-width: 600px) {
  .feed__stream {
    padding: 15px 10px;
  }

  .bubble__icon {
    width: 35px;
    height: 35px;
    font-size: 1.6rem;
    line-height: 2rem;
  }

  .bubble__core {
    max-width: calc(100% - 160px);
  }

  .bubble__text,
  .bubble__embed {
    padding: 10px;
  }

  .bubble__embed--link {
    min-height: 80px;
  }

  .bubble__assets {
    margin: 10px;
  }

  .bubble__embed--link .bubble__embed-image {
    position: relative;
    max-width: 100px;
    width: 100%;
    top: 0;
    left: 0;
  }
}

@media only screen and (max-width: 450px) {
  .bubble__core {
    max-width: calc(100% - 120px);
  }

  .bubble__text blockquote {
    padding-top: 10px;
  }

  .bubble__text blockquote:after {
    width: 25px;
    height: 20px;
  }
}
</style>