<template>
  <DashboardScreen v-if="isBackend()" />
  <router-view v-else />
</template>

<script>

import { auth } from './firebase';

import DashboardScreen from '@/components/screens/dashboard/DashboardScreen.vue'

export default {
  name: 'App',

  components: {
    DashboardScreen,
  },

  data() {
    return {
      currentUser: auth.currentUser
    }
  },

  methods: {

    isBackend() {
      return this.currentUser != null && this.$route.meta.requiresAuth === true;
    }

  }

}
</script>

<style>
@import '@/assets/css/layout/variables.css';
@import '@/assets/css/layout/fonts.css';
@import '@/assets/css/layout/layout.css';

/* User Colors */
.turquoise {
  background: var(--bubble-turquoise);
}

.pink {
  background: var(--bubble-pink);
}

.orange {
  background: var(--bubble-orange);
}

.blue {
  background: var(--bubble-blue);
}

.grey {
  background: var(--bubble-grey);
}

.dark-grey {
  background: var(--bubble-dark-grey);
}
</style>
