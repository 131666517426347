<template>
  <div class="form-group" :class="isValid">
    <svg class="icon">
      <use xlink:href="/assets/img/sprite.svg#password"></use>
    </svg>
    <input v-model="password" :type="passwordType" :name="name" :placeholder="placeholder ? placeholder : 'PASSWORD'"
      :required="required" @blur="$emit('validate')" />
    <span>password</span>
    <svg class="toggle-password" @click="togglePasswordVisibility" :class="this.passwordType === 'text' ? 'reveal' : ''">
      <use xlink:href="/assets/img/sprite.svg#hide"></use>
      <use xlink:href="/assets/img/sprite.svg#view"></use>
    </svg>
  </div>
</template>

<script>
export default {
  props: ['val', 'validation', 'name', 'required', 'placeholder'],

  data() {
    return {
      password: '',
      passwordType: 'password',
    };
  },

  methods: {
    togglePasswordVisibility() {
      this.passwordType = this.passwordType === 'password' ? 'text' : 'password';
    },
  },

  computed: {
    isValid: function () {
      if (this.validation != null) {
        return this.validation ? 'valid' : 'invalid';
      }
      return '';
    },
  },

  watch: {
    password() {
      this.$emit('update_val', this.password);
    },
  },
};
</script>
