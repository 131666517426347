<template>
  <div class="user-account" @click="toggleDropdown">{{ initials }}</div>

  <ul class="drop-down" :class="{ 'drop-down--open': isDropdownOpen }">
    <li><a href="/profile" @click.prevent="this.$router.push('/profile');">Profile</a></li>
    <!-- <li class="expanded" :class="{ 'expanded--open': isExpanded('billing') }">
      <span @click="toggleExpansion('billing')">Billing</span>
      <ul>
        <li><a href="/billing">Plans</a></li>
        <li><a href="/invoices">Invoices</a></li>
      </ul>
    </li> -->
    <li>
      <a href="#" @click.prevent="logout">Log out</a>
    </li>
  </ul>
</template>
          
<script>

import { auth } from '@/firebase';

export default {

  components: {
  },

  data() {
    return {
      isDropdownOpen: false,
      expandedSections: {},
    };
  },

  computed: {
    initials() {
      return auth.currentUser.displayName ? auth.currentUser.displayName.trim().substring(0, 2).toLocaleUpperCase() : '';
    },
  },

  methods: {

    async logout() {
      try {
        await auth.signOut();
        this.$router.push('/');
      } catch (error) {
        console.error('Logout failed:', error);
      }
    },

    toggleDropdown() {
      this.isDropdownOpen = !this.isDropdownOpen;
    },

    isExpanded(sectionName) {
      return this.expandedSections[sectionName] === true;
    },

    toggleExpansion(sectionName) {
      this.expandedSections[sectionName] = !this.isExpanded(sectionName);
    },
  },

};
</script>
        
<style scoped>
.user-account {
  position: absolute;
  right: 10px;
  top: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  transform: translateY(-50%);
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: bold;
  border-radius: 50%;
  background: #04b5c4;
  color: #fff;
  cursor: pointer;
}

.drop-down {
  position: fixed;
  top: 60px;
  right: -240px;
  width: 240px;
  transform: translateX(0);
  font-size: 1.6rem;
  line-height: 1.7rem;
  background: #fff;
  border-radius: 10px 0 10px 10px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.12);
  list-style-type: none;
  transition: 0.2s;
}

.drop-down--open {
  transform: translateX(calc(-100% - 20px));
}

.drop-down>li {
  position: relative;
  padding: 20px;
  color: var(--gray);
}

.drop-down>li:not(:last-child):before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  height: 1px;
  background: #dedede;
}

.drop-down>li>* {
  position: relative;
  display: block;
  cursor: pointer;
}

.expanded:hover>span:before,
.drop-down>li>*:hover:before {
  content: '';
  position: absolute;
  left: -20px;
  top: 0;
  bottom: 0;
  width: 3px;
  background-image: linear-gradient(to bottom, var(--light-blue), var(--purple-blue), var(--purple));
}

.expanded>span {
  position: relative;
  cursor: pointer;
}

.expanded>span:after {
  content: '';
  position: absolute;
  right: 0;
  top: 6px;
  border-right: 5px solid #fff;
  border-bottom: 5px solid var(--light-blue);
  border-left: 5px solid #fff;
  background: var(--light-blue);
}

.expanded ul {
  display: none;
  margin: 10px 0 0 10px;
  font-size: 1.3rem;
  line-height: 1.8rem;
  list-style-type: none;
  cursor: default;
}

.expanded ul:before {
  display: none;
}

.expanded li:not(:last-child) {
  margin-bottom: 8px;
}

.expanded li>* {
  display: block;
  cursor: pointer;
}

.expanded--open>span:after {
  transform: scaleY(-1);
}

.expanded--open ul {
  display: block;
}

.drop-down>li>a:hover,
.drop-down>li>span:hover,
.expanded li:hover {
  color: var(--blue);
}
</style>