import { createApp } from 'vue'
import { createStore } from 'vuex'
import App from './App.vue'

import texteditor from '@/components/modules/texteditor'
import stream from '@/components/modules/stream'
import frontStream from '@/components/modules/frontStream'

import router from './router';
import { auth } from './firebase';
import { onAuthStateChanged } from 'firebase/auth';
import VueClipboard from 'vue3-clipboard'

// Use a promise to handle the first authentication state change
const authReady = new Promise((resolve) => {
  onAuthStateChanged(auth, (user) => {
    if (user) {
      // If user is signed in, let them proceed to the route they are trying to access
      const originalPath = router.currentRoute.value.fullPath;
      if (originalPath === '/login') {
        router.push('/dashboard');
      }
    } else {
      // If user is not signed in, navigate to login
      if (router.currentRoute.value.meta.requiresAuth) {
        router.push('/login');
      }
    }
    resolve(user);
  });
});

const store = createStore({
  state: {},
  getters: {},
  mutations: {},
  actions: {},
  modules: {},
});

function unregisterModule(store, moduleName) {
  if (store.hasModule(moduleName)) {
    store.unregisterModule(moduleName);
  }
}

// Wait for the first auth state change to complete
authReady.then((user) => {
  const app = createApp(App);
  app.use(router);
  app.use(store);

  router.isReady().then(() => {
    if (user) {
      if (router.currentRoute.value.meta.requiresAuth) {
        store.registerModule('texteditor', texteditor);
        store.registerModule('stream', stream);
        unregisterModule(store, 'frontStream');
      } else {
        store.registerModule('frontStream', frontStream);
        unregisterModule(store, 'texteditor');
        unregisterModule(store, 'stream');
      }
    } else {
      store.registerModule('frontStream', frontStream);
      unregisterModule(store, 'texteditor');
      unregisterModule(store, 'stream');
    }

    app.use(VueClipboard, {
      autoSetContainer: true,
      appendToBody: true,
    });

    app.mount('#app');
  });
});