<template>
  <div v-if="event">
    <div class="stream-board">

      <h1>{{ event.title }}</h1>
      <div class="stream-board__info">
        <div>
          <svg>
            <use xlink:href="/assets/img/sprite.svg#clock"></use>
          </svg>
          {{ date }}
        </div>
        <div>
          <svg>
            <use xlink:href="/assets/img/sprite.svg#comment"></use>
          </svg>
          {{ messagesAmount }}
        </div>
        <div v-if="event.status === 1">
          <svg>
            <use xlink:href="/assets/img/sprite.svg#user"></use>
          </svg>
          {{ users }}
        </div>
      </div>
    </div>

    <!-- <Match v-if="match.exist" :type="match.type" /> -->

    <div class="messages" v-if="event.status === 1">
      <TextEditor />
      <button @click="togglePermission" :class="{ disabled: userPermission }" class="messages__toggle"
        v-if="event.status === 1">
        <span>
          <svg>
            <use :xlink:href="permission.icon"></use>
          </svg>
        </span>
        <div>{{ permission.text }}</div>
      </button>
      <span v-if="pendingMessagesAmount" class="messages__counter">
        <span>{{ pendingMessagesAmount }} {{ pendingMessagesAmount > 1 ? 'Messages' : 'Message' }}</span>
        <span> Latest on: {{ latestMessageTime }}</span>
      </span>
      <PendingMessages v-if="pendingMessagesAmount" />
    </div>

    <ul class="feed-menu">
      <li @click="streamTab('live')" :class="{ 'active': activeTab === 'live' }">
        Live
      </li>
      <li v-if="event.status == 1" @click="streamTab('ignored')" :class="{ 'active': activeTab === 'ignored' }">
        Ignored
      </li>
      <li v-if="event.status == 1" @click="streamTab('blocked')" :class="{ 'active': activeTab === 'blocked' }">
        Blocked
      </li>
    </ul>

    <FeedMain :eventId="eventId" />

    <!-- <ModalAssetsLightbox /> -->
    <!-- <ModalUserProfile />
    <ModalArchiveStream />
    <ModalEmbedStream />
    <ModalMessageDelete /> -->
  </div>
</template>

<script>

import moment from 'moment'

import { mapGetters } from 'vuex'
import TextEditor from '@/components/misc/editor/TextEditor'
import PendingMessages from '@/components/events/pending_messages/PendingMessages'
import FeedMain from '@/components/misc/feed_main/FeedMain'
// import Match from '../../components/matches/Match'
// import ModalUserProfile from '../../../components/modals/UserProfile'
// import ModalArchiveStream from '../../../components/modals/ArchiveStream'
// import ModalAssetsLightbox from '@/components/misc/modals/AssetsLightbox'
// import ModalEmbedStream from '../../../components/modals/EmbedStream'
// import ModalMessageDelete from '../../../components/modals/MessageDelete'

export default {

  props: ['eventId'],

  components: {
    TextEditor,
    PendingMessages,
    FeedMain,
    // Match,
    // ModalUserProfile,
    // ModalArchiveStream,
    // ModalAssetsLightbox,
    // ModalEmbedStream,
    // ModalMessageDelete,
  },

  data() {
    return {
      isLoading: false,
      isOnline: false,
      usersOnline: null,
    };
  },

  async created() {
    this.getEvent();
    this.$store.dispatch('stream/fetchPendingMessages', this.eventId);
    this.usersOnline = await this.$store.dispatch('stream/fetchOnlineUsers');
  },

  methods: {

    async getEvent() {
      await this.$store.dispatch('stream/fetchStream', this.eventId);
    },

    togglePermission() {
      this.$store.dispatch('stream/togglePermission', this.eventId);
    },

    streamTab(tab) {
      this.$store.dispatch('stream/streamTab', tab);
    },

    moment(date) {
      return moment(date);
    },

    clearEventData() {
      this.$store.dispatch('stream/clearEventData');
      this.isLoading = false;
      this.isOnline = false;
    },

  },

  computed: {
    // ...mapGetters(['user']),

    ...mapGetters('stream', {
      event: 'event',
      info: 'streamInfo',
      users: 'onlineUsers',
      userPermission: 'userPermission',
      pendingMessagesAmount: 'pendingMessagesAmount',
      messagesAmount: 'messagesAmount',
      latestPending: 'pendingLatestMessage',
      activeTab: 'activeStreamTab',
      match: 'match',
    }),

    permission() {
      let obj = {};

      if (this.userPermission) {
        obj.icon = '/assets/img/sprite.svg#enable-comments';
        obj.text = 'Disable messages from users';
      }
      else {
        obj.icon = '/assets/img/sprite.svg#disable-comments';
        obj.text = 'Enable messages from users';
      }
      return obj;
    },

    latestMessageTime() {
      return moment(this.latestPending.created.toDate()).format('HH:mm');
    },

    date() {
      return this.event.created ? moment(this.event.created.toDate()).format('DD.MM.YY | hh:mm') : '';
    }
  },

  beforeUnmount() {
    this.clearEventData();
    if (this.usersOnline) {
      this.usersOnline();
    }
  },

}
</script>

<style scoped>
main {
  max-width: 840px;
}

.stream-board {
  margin: 0 40px;
  padding: 20px;
  color: #fff;
  background: var(--gray);
  text-align: center;
}

.stream-board h1 {
  margin-bottom: 15px;
  font-size: 2.8rem;
  line-height: 3rem;
}

.stream-board__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
}

.stream-board__info div {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: bold;
}

.stream-board__info svg {
  width: 22px;
  height: 22px;
  -webkit-box-flex: 1;
  -ms-flex: 1 0 auto;
  flex: 1 0 auto;
  margin-right: 10px;
  fill: #fff;
}

.messages {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.messages:before {
  content: '';
  position: absolute;
  left: 40px;
  right: 40px;
  top: 0;
  bottom: -40px;
  background: -webkit-gradient(linear, left top, left bottom, from(var(--blue)), to(transparent));
  background: -o-linear-gradient(top, var(--blue), transparent);
  background: linear-gradient(to bottom, var(--blue), transparent);
}

.messages__toggle {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  cursor: pointer;
  background: none;
  border: none;
  outline: none;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-family: 'Ubuntu', sans-serif;
  text-decoration: underline;
  color: #fff;
}

.messages__toggle span {
  width: 35px;
  height: 35px;
  margin-right: 5px;
  padding: 8px;
  border-radius: 50%;
  background: -webkit-gradient(linear, left top, right top, from(var(--fuchsia)), to(var(--orange)));
  background: -o-linear-gradient(left, var(--fuchsia), var(--orange));
  background: linear-gradient(to right, var(--fuchsia), var(--orange));
}

.messages__toggle.disabled span {
  background: -webkit-gradient(linear, left top, right top, from(var(--light-blue)), color-stop(var(--purple-blue)), to(var(--purple)));
  background: -o-linear-gradient(left, var(--light-blue), var(--purple-blue), var(--purple));
  background: linear-gradient(to right, var(--light-blue), var(--purple-blue), var(--purple));
}

.messages__toggle svg {
  width: 100%;
  height: 100%;
  fill: #fff;
}

.messages__counter {
  position: relative;
  margin: 20px 0 30px;
  font-size: 1.4rem;
  line-height: 1.3rem;
  color: #fff;
}

.messages__counter span:nth-child(1) {
  position: relative;
  margin-right: 10px;
  font-weight: bold;
}

.messages__counter span:nth-child(1):before {
  content: '';
  position: absolute;
  right: -8px;
  top: 0;
  bottom: 0;
  width: 1px;
  background: #fff;
}

.feed-menu {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin: 25px 0 20px;
  font-size: 1.8rem;
  line-height: 2rem;
  font-weight: 400;
  color: #fff;
  list-style-type: none;
  z-index: 1;
}

.feed-menu li {
  margin: 0 20px;
  padding-bottom: 5px;
  cursor: pointer;
}

.feed-menu li.active {
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
}

.feed-menu li.active:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 3px;
  background: #fff;
}

@media only screen and (max-width: 600px) {
  .stream-board {
    margin: 0 10px;
  }

  .stream-board h1 {
    font-size: 2.5rem;
  }

  .stream-board__info {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .stream-board__info div {
    margin: 0 10px 5px;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    font-size: 1.3rem;
  }

  .stream-board__info svg {
    width: 15px;
    height: 15px;
    -webkit-box-flex: 0;
    -ms-flex: 0 0 auto;
    flex: 0 0 auto;
  }

  .messages:before {
    left: 10px;
    right: 10px;
  }

  .feed-menu {
    font-size: 1.6rem;
  }

  .feed-menu li {
    margin: 0 10px;
  }

  .feed-menu li.active:before {
    bottom: 0px;
    height: 2px;
  }
}
</style>
