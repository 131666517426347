<template>
  <div class="editor" :class="[tab, tabVariation]">

    <CKEditorComponent />

    <label class="editor__autosend" @click.prevent="toggleAutosend">
      <input v-model="autoSend" type="checkbox" />Enter to submit
      <svg>
        <use xlink:href="/assets/img/sprite.svg#check"></use>
      </svg>
    </label>

    <label class="editor__submit" @click.prevent="submit" :class="submitPermission">
      <svg>
        <use xlink:href="/assets/img/sprite.svg#send"></use>
      </svg>
      <button>SEND</button>
    </label>

    <LinkInput v-if="linkInputActive" />

    <LinkPreview v-if="linkPreviewActive" />

    <DropZone v-if="dropzoneActive" />

    <EditorTabs />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CKEditorComponent from '@/components/misc/editor/CKEditorComponent'
import LinkInput from '@/components/misc/editor/linkinput/LinkInput'
import LinkPreview from '@/components/misc/editor/linkpreview/LinkPreview'
import DropZone from '@/components/misc/editor/dropzone/DropZone'
import EditorTabs from '@/components/misc/editor/tabs/EditorTabs'

export default {

  components: {
    CKEditorComponent,
    LinkInput,
    LinkPreview,
    DropZone,
    EditorTabs,
  },

  created() {
    let that = this;

    window.addEventListener('resize', function () {
      if (window.innerWidth < 600 && that.autoSend) {
        that.$store.dispatch('texteditor/toggleAutosend', false);
      }
    });
  },

  methods: {
    submit() {
      const eventId = this.$route.params.eventId;
      this.$store.dispatch('texteditor/sendContent', eventId);
    },

    toggleAutosend() {
      this.$store.dispatch('texteditor/toggleAutosend');
    },

  },

  computed: {
    ...mapGetters('texteditor', {
      tab: 'activeTab',
      tabVariation: 'tabVariation',
      autoSend: 'autoSubmit',
      editorText: 'editorText',
      linkExist: 'linkExist',
      canSubmit: 'canSubmit',
    }),

    linkInputActive() {
      return !this.linkExist && this.tab === 'link';
    },

    linkPreviewActive() {
      return this.linkExist && this.tab === 'link';
    },

    dropzoneActive() {
      return this.tab === 'asset';
    },

    submitPermission() {
      return this.canSubmit ? '' : 'disabled';
    },
  },

}
</script>

<style>
.editor {
  position: relative;
  width: 100%;
  margin-bottom: 50px;
  background: #fff;
  color: #000;
  border-radius: 0 10px 10px 0;
}

.editor__autosend {
  position: absolute;
  padding-left: 30px;
  z-index: 1;
  cursor: pointer;
  color: #6a6a6a;
  top: 23px;
  right: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.editor__autosend svg {
  position: absolute;
  width: 14px;
  height: 14px;
  left: 3px;
  top: 8px;
  fill: #6a6a6a;
  opacity: 0;
}

.editor__autosend:before {
  content: '';
  position: absolute;
  width: 18px;
  height: 18px;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  border-radius: 2px;
  border: 1px solid #6e6e6e;
}

.editor__autosend input {
  position: absolute;
  top: 5px;
  left: 0;
  width: 20px;
  height: 20px;
  opacity: 0;
}

.editor__autosend input:checked+svg {
  opacity: 1;
}

.editor__submit {
  position: absolute;
  top: calc(50% + 23px);
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 20px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  cursor: pointer;
}

.editor__submit.disabled {
  pointer-events: none;
}

.editor__submit svg {
  width: 20px;
  height: 17px;
  margin-right: 5px;
  fill: var(--blue);
}

.editor__submit.disabled svg {
  fill: var(--light-gray);
}

.editor__submit button {
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: bold;
  border: none;
  background: transparent;
  color: var(--blue);
  cursor: pointer;
  outline: none;
}

.editor__submit.disabled button {
  color: var(--light-gray)
}

.editor.asset .editor__submit {
  top: auto;
  bottom: 5px;
  height: 50px;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}

.editor.asset--preview .editor__submit {
  bottom: auto;
  top: 101px;
  height: auto;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.editor.link .editor__submit {
  top: calc(50% + 56px);
}

.editor.link--preview .editor__submit {
  top: 101px;
}

.ck-balloon-panel_visible {
  display: none !important;
}

@media only screen and (max-width: 600px) {
  .editor__autosend {
    display: none;
  }

  .editor {
    border-radius: 0 10px 0 0;
  }

  .editor__submit {
    top: 100%;
    height: 40px;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    padding: 0 20px;
    border-radius: 0 0 10px 10px;
    pointer-events: all;
    background: #fff;
  }

  .editor.asset .editor__submit {
    height: 40px;
    bottom: auto;
  }

  .editor.asset--preview .editor__submit {
    top: 100%;
    -webkit-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
  }

  .editor.link .editor__submit,
  .editor.link--preview .editor__submit {
    top: 100%;
  }
}
</style>