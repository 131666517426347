<template>
  <component :is="tag" class="dz-preview dz-file-preview">
    <div class="dz-image">
      <div :style='"background-image: url(" + file.url + ")"'></div>
    </div>
    <div class="dz-details">
      <div class="dz-size"><span data-dz-size></span></div>
      <div class="dz-filename"><span data-dz-name></span></div>
    </div>
    <!-- <div class="dz-progress"></div> -->
    <div class="dz-error-message"><span data-dz-errormessage></span></div>
    <div class="dz-success-mark"></div>
    <div class="dz-error-mark"></div>
    <svg class="success-indicator">
      <use xlink:href="/assets/img/sprite.svg#success"></use>
    </svg>
    <div @click="emitRemove" class="dz-remove"></div>
  </component>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
  file: { type: Object, required: true },
  tag: { type: String, default: 'div' },
});

const emit = defineEmits(['remove']);

const emitRemove = () => {
  emit('remove', props.file);
};
</script>

<style>
.file-preview {
  width: 20%;
  margin: 1rem 2.5%;
  position: relative;
  aspect-ratio: 1 / 1;
  overflow: hidden;
}

.file-preview img {
  width: 100%;
  height: 100%;
  display: block;
  object-fit: cover;
}

.file-preview .close-icon,
.file-preview .status-indicator {
  --size: 20px;
  position: absolute;
  line-height: var(--size);
  height: var(--size);
  border-radius: var(--size);
  box-shadow: 0 0 5px currentColor;
  right: 0.25rem;
  appearance: none;
  border: 0;
  padding: 0;
}

.file-preview .close-icon {
  width: var(--size);
  font-size: var(--size);
  background: #933;
  color: #fff;
  top: 0.25rem;
  cursor: pointer;
}

.file-preview .status-indicator {
  font-size: calc(0.75 * var(--size));
  bottom: 0.25rem;
  padding: 0 10px;
}

.file-preview .loading-indicator {
  animation: pulse 1.5s linear 0s infinite;
  color: #000;
}

.file-preview .success-indicator {
  background: #6c6;
  color: #040;
}

.file-preview .failure-indicator {
  background: #933;
  color: #fff;
}

@keyframes pulse {
  0% {
    background: #fff;
  }

  50% {
    background: #ddd;
  }

  100% {
    background: #fff;
  }
}

.editor.asset--preview .dropzone-wrap {
  position: relative;
  top: 0;
  margin: 10px;
}

.editor.asset--preview .dropzone-wrap:before {
  content: '';
  position: absolute;
  top: -5px;
  left: 0;
  right: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.2);
}

.dropzone-previews .dz-preview {
  display: inline-block;
  position: relative;
  margin: 0 15px 0 0;
  color: #000;
}

.dropzone-previews .dz-preview:last-child {
  margin-right: 0;
}

.dropzone-previews .dz-image {
  margin: 0 0 5px;
  width: 70px;
  height: 90px;
}

.dropzone-previews .dz-size {
  display: none;
}

.dropzone-previews .dz-filename {
  max-width: 70px;
  font-size: 1rem;
  line-height: 1.6rem;
  color: #6a6a6a;
  -o-text-overflow: ellipsis;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.dropzone-previews .dz-image>div {
  width: inherit;
  height: inherit;
  background-size: cover;
  background-position: center;
}

.dropzone-previews .dz-remove {
  position: absolute;
  top: 0;
  right: 0;
  width: 25px;
  height: 25px;
  text-indent: -9999px;
  overflow: hidden;
  -webkit-transform: translate(50%, -50%);
  -ms-transform: translate(50%, -50%);
  transform: translate(50%, -50%);
  -webkit-box-shadow: 0 4px 4px rgba(0, 0, 0, 0.29);
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.29);
  background: #000;
  border-radius: 50%;
  cursor: pointer;
}

.dropzone-previews .dz-remove:after {
  content: '\2715';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 15px;
  height: 29px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  text-indent: 0;
  text-align: center;
  color: #fff;
}

.dropzone-previews .dz-preview.dz-error .dz-error-message {
  position: absolute;
  padding: 5px;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  color: #fff;
  font-size: 1rem;
  line-height: 1.6rem;
  ;
  background: rgba(0, 0, 0, 0.9);
}

.dropzone-previews .dz-preview .dz-progress {
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  width: 65px;
  height: 5px;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  border-radius: 5px;
  overflow: hidden;
  background: rgba(214, 214, 214, 0.9);
}

.dropzone-previews .dz-preview .dz-progress .dz-upload {
  position: absolute;
  top: 50%;
  left: 0;
  width: 0;
  height: 100%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  background: var(--blue);
  -webkit-transition: width 0.5s ease-in-out;
  -o-transition: width 0.5s ease-in-out;
  transition: width 0.5s ease-in-out;
}

.success-indicator {
  position: absolute;
  top: calc(50% - 10px);
  left: 50%;
  width: 25px;
  height: 25px;
  -webkit-transform: translate(-50%, -30%);
  -ms-transform: translate(-50%, -30%);
  transform: translate(-50%, -30%);
  border-radius: 50%;
  fill: var(--blue);
  background: rgba(255, 255, 255, 0.85);
  /* opacity: 0; */
  -webkit-transition: opacity 0.4s, -webkit-transform 0.7s;
  transition: opacity 0.4s, -webkit-transform 0.7s;
  -o-transition: opacity 0.4s, transform 0.7s;
  transition: opacity 0.4s, transform 0.7s;
  transition: opacity 0.4s, transform 0.7s, -webkit-transform 0.7s;
}

.dropzone-previews .dz-preview.dz-success.dz-complete .dz-progress {
  display: none;
}

.dropzone-previews .dz-preview.dz-success.dz-complete .success-indicator {
  opacity: 1;
  -webkit-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}
</style>
