<template>
  <div class="flex-center position-ref full-height">
    <div class="code">
      <h1>Page Not Found</h1>
    </div>
    <div class="message">
      <p>Sorry, the page you are looking for does not exist.</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound'
}
</script>

<style scoped>
.full-height {
  height: 100vh;
}

.flex-center {
  align-items: center;
  display: flex;
  justify-content: center;
}

.position-ref {
  position: relative;
}

.code {
  border-right: 2px solid;
  font-size: 2.6rem;
  line-height: 5.4rem;
  text-align: center;
  padding: 0 15px;
}

.message {
  font-size: 18px;
  text-align: center;
  padding: 10px;
}
</style>