<template>
  <div class="bubble__text" v-html="msg" v-if="!isGuest"></div>
  <div class="bubble__text" v-if="isGuest">{{ msg }}</div>
</template>

<script>
export default {

  props: ['msg', 'isGuest'],

}
</script>

<style scoped>
/* Message */
.bubble__text {
  padding: 15px 20px;
  word-break: break-word;
  text-align: left;
}

.bubble__text>* {
  margin-bottom: 10px;
}

.bubble__text>*:last-child {
  margin-bottom: 0;
}

.bubble__text blockquote {
  position: relative;
  padding: 15px 0 0 20px;
  font-size: 1.6rem;
  line-height: 2.4rem;
  font-weight: 500;
  z-index: 1;
}

.bubble__text blockquote:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 35px;
  height: 25px;
  background-image: url(@/assets/img/quotes.svg);
  z-index: -1;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
}

.bubble__text b,
.bubble__text strong {
  font-weight: bold;
}

.bubble__text ul,
.bubble__text ol {
  margin-left: 20px;
  list-style-type: none;
}

.bubble__text ol {
  counter-reset: ordered_item;
}

.bubble__text li {
  position: relative;
  font-size: 1.4rem;
  line-height: 2.2rem;
}

.bubble__text ul>li:after {
  content: '';
  position: absolute;
  top: 10px;
  left: -15px;
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: var(--blue);
}

.bubble__text ol>li {
  counter-increment: ordered_item;
}

.bubble__text ol>li:after {
  content: counter(ordered_item)".";
  position: absolute;
  left: -20px;
  top: 0;
  font-size: 1.2rem;
  line-height: 2.2rem;
  font-weight: 500;
  color: var(--blue);
}

.bubble__text a {
  text-decoration: underline;
  font-weight: 500;
  color: var(--blue);
}

.bubble__core--enclose .bubble__text {
  border-bottom: 1px solid var(--blue);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}
</style>