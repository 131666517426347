import axios from 'axios'
import { db, auth } from '@/firebase'
import { getDatabase, ref, set, onDisconnect } from 'firebase/database';
import { serverTimestamp, Timestamp } from 'firebase/firestore'
import router from '@/router';

const state = function () {
  return {
    info: {
      status: null,
      userMessages: null,
    },
    username: '',
    lastVisible: null,
    latestInitialMessageTime: null,
    messagesListenerUnsubscribe: null,
    initialized: false,
    messages: [],
    streamFilters: {
      highlights: false,
      host: false,
    },
    helpers: {
      nextPage: null,
      nextPageBusy: false,
    },
  }
}

const getters = {
  streamInfo(state) {
    return state.info;
  },

  username(state) {
    return state.username;
  },

  streamFilterFlags(state) {
    return state.streamFilters;
  },

  eventInitialized(state) {
    return state.initialized;
  },

  liveMessages(state) {
    let messages = state.messages;

    if (state.streamFilters.highlights && state.streamFilters.host) {
      messages = messages.filter(function (msg) {
        return msg.highlight === true && msg.guest === false;
      });
    }
    else {
      if (state.streamFilters.highlights) {
        messages = messages.filter(function (msg) {
          return msg.highlight === true;
        });
      }
      if (state.streamFilters.host) {
        messages = messages.filter(function (msg) {
          return msg.guest === false;
        });
      }
    }

    return messages;
  },
}

const actions = {
  async fetchStream({ commit, dispatch, state }, eventId) {
    commit('SET_EVENT_ID', eventId);
    try {
      const eventRef = db.collection('events').doc(eventId);

      eventRef.onSnapshot(async (eventDoc) => {
        if (eventDoc.exists) {
          const eventData = eventDoc.data();
          commit('SET_STREAM_INFO', eventData);

          if (!state.initialized) {
            if (eventData.archived === true) {
              try {
                const response = await axios.get(`https://st1.livecaster.io/lc-events/events/${eventId}.json`);
                const messages = [];
                response.data.messages.forEach((messageData) => {
                  messageData.created = new Timestamp(messageData.created._seconds, messageData.created._nanoseconds);
                  if (messageData.updated) {
                    messageData.updated = new Timestamp(messageData.updated._seconds, messageData.updated._nanoseconds);
                  }
                  messages.push(messageData);
                });
                commit('SET_STREAM_MESSAGES', messages);
              } catch (axiosError) {
                console.error('Error fetching archived event:', axiosError);
              }
            } else {
              await dispatch('fetchMessages', eventId);
              dispatch('listenForMessages');
            }
            commit('SET_INITIALIZED', true);
          }
        } else {
          router.push('/404');
        }
      });
    } catch (error) {
      console.error('Error fetching event:', error);
    }
  },

  async fetchMessages({ state, commit }) {
    try {
      const eventRef = db.collection('events').doc(state.eventId);
      const messagesRef = eventRef.collection('messages');
      let query = messagesRef.where('status', '==', 1).orderBy('created', 'desc').limit(10);
      if (state.lastVisible) {
        query = query.startAfter(state.lastVisible);
      }

      const querySnapshot = await query.get();

      const messages = [];

      querySnapshot.forEach((doc) => {
        const messageData = doc.data();
        const messageId = doc.id;
        messages.push({ id: messageId, ...messageData });
      });

      if (messages.length > 0) {
        commit('SET_STREAM_MESSAGES_PAGINATED', messages);
        const newLastVisible = querySnapshot.docs[querySnapshot.docs.length - 1];
        commit('SET_LAST_VISIBLE', newLastVisible);

        const latestMessageTime = messages[0].created;
        commit('SET_LATEST_INITIAL_MESSAGE_TIME', latestMessageTime);

        return true;
      }
      else {
        return false;
      }

    } catch (error) {
      console.error('Error getting messages:', error);
    }
  },

  listenForMessages({ state, commit }) {

    if (state.messagesListenerUnsubscribe) {
      state.messagesListenerUnsubscribe();
    }

    if (state.latestInitialMessageTime) {

      const eventRef = db.collection('events').doc(state.eventId);
      const messagesRef = eventRef.collection('messages');

      const unsubscribe = messagesRef
        .where('status', '==', 1)
        .where('created', '>', state.latestInitialMessageTime)
        .orderBy('created', 'desc')
        .onSnapshot((querySnapshot) => {
          querySnapshot.docChanges().forEach((change) => {
            const messageData = change.doc.data();
            const messageId = change.doc.id;
            const fullMessage = { id: messageId, ...messageData };

            if (change.type === 'added') {
              commit('ADD_NEW_MESSAGE', fullMessage);
            } else if (change.type === 'modified') {
              commit('UPDATE_MESSAGE', fullMessage);
            } else if (change.type === 'removed') {
              commit('REMOVE_MESSAGE', messageId);
            }
          });
        });

      commit('SET_MESSAGES_LISTENER_UNSUBSCRIBE', unsubscribe);
    }
  },

  unsubscribeFromMessages({ state }) {
    if (state.messagesListenerUnsubscribe) {
      state.messagesListenerUnsubscribe();
      state.messagesListenerUnsubscribe = null;
    }
  },

  setUsername({ commit }, name) {
    commit('SET_USERNAME', name);
  },

  async sendMessage({ state }, message) {
    try {
      const eventRef = db.collection("events").doc(state.eventId);
      const messagesCollection = eventRef.collection("messages");
      const timestamp = serverTimestamp();
      const user = auth.currentUser;

      if (user) {
        await messagesCollection.add({
          type: 'text',
          oembed: false,
          message: message,
          status: 0,
          highlight: false,
          user: state.username,
          uid: user.uid,
          guest: true,
          created: timestamp,
          images: [],
        });
      }
    } catch (error) {
      // console.error('Error sending message:', error);
    }
  },

  filterMessages({ commit }, term) {
    commit('FILTER_STREAM', term);
  },

  async handlePresence({ state }) {
    if (!auth.currentUser) {
      auth.signInAnonymously()
        .then(() => {
          const user = auth.currentUser;
          if (user) {
            // Realtime Database reference for user's presence
            const db = getDatabase();
            const userPresenceRef = ref(db, 'events/' + state.eventId + '/onlineUsers/' + user.uid);

            // Set the user's presence to true and handle disconnection
            set(userPresenceRef, { online: true }).then(() => {
              // Remove the user's presence entry upon disconnection
              onDisconnect(userPresenceRef).remove();
            });
          }
        });
      // .catch(error => {
      //   // console.error('Authentication failed:', error);
      // });
    }
    else {
      const user = auth.currentUser;
      if (user) {
        // Realtime Database reference for user's presence
        const db = getDatabase();
        const userPresenceRef = ref(db, 'events/' + state.eventId + '/onlineUsers/' + user.uid);

        // Set the user's presence to true and handle disconnection
        set(userPresenceRef, { online: true }).then(() => {
          // Remove the user's presence entry upon disconnection
          onDisconnect(userPresenceRef).remove();
        });
      }
    }
  }
}

const mutations = {

  SET_EVENT_ID(state, eventId) {
    state.eventId = eventId;
  },

  SET_INITIALIZED(state, value) {
    state.initialized = value;
  },

  SET_STREAM_INFO(state, data) {
    state.info.status = data.status;
    state.info.userMessages = data.userMessages;
  },

  SET_LATEST_INITIAL_MESSAGE_TIME(state, timestamp) {
    state.latestInitialMessageTime = timestamp;
  },

  SET_STREAM_MESSAGES(state, data) {
    state.messages = data;
  },

  SET_LAST_VISIBLE(state, lastVisible) {
    state.lastVisible = lastVisible;
  },

  SET_STREAM_MESSAGES_PAGINATED(state, messages) {
    state.messages = [...state.messages, ...messages];
  },

  UPDATE_MESSAGE(state, updatedMessage) {
    console.log(updatedMessage)
    const index = state.messages.findIndex(message => message.id === updatedMessage.id);
    if (index !== -1) {
      state.messages.splice(index, 1, updatedMessage);
    }
  },

  ADD_NEW_MESSAGE(state, message) {
    state.messages.unshift(message);
  },

  REMOVE_MESSAGE(state, messageId) {
    state.messages = state.messages.filter(message => message.id !== messageId);
  },

  HIGHLIGHT_MESSAGE(state, data) {
    let messages = state.messages;

    messages = messages.map(msg => {
      if (msg.id == data.message_id) {
        msg.highlight = data.highlight;
      }
      return msg;
    })

    state.messages = messages;
  },

  DELETE_MESSAGE(state, data) {
    let messages = state.messages;

    messages = messages.filter(msg => {
      return msg.id !== data.message_id
    })

    state.messages = messages;
  },

  SET_USERNAME(state, name) {
    state.username = name;
  },

  FILTER_STREAM(state, term) {
    switch (term) {
      case 'highlights':
        state.streamFilters.highlights = (state.streamFilters.highlights) ? false : true;
        break;
      case 'host':
        state.streamFilters.host = (state.streamFilters.host) ? false : true;
        break;
    }
  },

  ADD_MESSAGE(state, msg) {
    state.messages.unshift(msg);
  },

  ADD_MORE_MESSAGES(state, data) {

    data.messages.forEach(msg => {
      let pos = state.messages.findIndex(el => el.id === msg.id);

      if (pos != -1) {
        state.messages[pos] = msg;
      }
      else {
        state.messages.push(msg);
      }
    });

    state.helpers.nextPage = data.next_page;

  },

  SET_NEXT_PAGE_STATUS(state, val) {
    state.helpers.nextPageBusy = val;
  },

  SET_MESSAGES_LISTENER_UNSUBSCRIBE(state, unsubscribe) {
    state.messagesListenerUnsubscribe = unsubscribe;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}