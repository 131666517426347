<template>
  <div class="dropzone-wrap">

    <DropZone class="dropzone" @files-dropped="addFiles" @upload-success="onUploadSuccess" @file-removed="removeFile" />

  </div>
</template>

<script>
import DropZone from '@/components/misc/dropzone/DropZone.vue';
import useFileList from '@/compositions/file-list';

export default {
  components: {
    DropZone
  },

  created() {
    this.fileList = useFileList();
  },

  methods: {
    addFiles(newFiles) {
      this.fileList.addFiles(newFiles);
    },

    onUploadSuccess(file) {
      this.$store.dispatch('texteditor/uploadedAssets', file.uploadedPath);
    },

    removeFile(file) {
      this.$store.dispatch('texteditor/removeAsset', file.uploadedPath);
    }

  }

};
</script>


<style scoped>
.dropzone-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  margin: 0 10px;
  background: #fff;
}

@media only screen and (max-width: 600px) {
  .dropzone {
    min-height: 80px;
  }
}
</style>