<template>
  <a class="bubble__embed" target="_blank" :href="media.url" :class="variation">

    <svg class="bubble__embed-indicator">
      <use :xlink:href="icon"></use>
    </svg>

    <figure class="bubble__embed-image" v-if="media.image.length">
      <img :src="media.image">
    </figure>

    <div class="bubble__embed-image" v-else-if="!media.image.length && type === 'video'">
      <span>Watch video</span>
    </div>

    <div class="bubble__embed-title" v-if="title">{{ title }}</div>
    <div class="bubble__embed-description" v-if="description">{{ description }}</div>

  </a>
</template>

<script>
export default {

  props: ['media'],

  computed: {
    title() {
      if (this.media.type) {
        if (this.media.type === 'link') {
          return this.media.provider;
        }
        else if (this.media.type === 'video') {
          return this.media.title;
        }
        else if (this.media.type === 'rich') {
          return this.media.author;
        }
      }
      return '';
    },

    description() {
      if (this.media.type) {
        if (this.media.type === 'link') {
          return this.media.title;
        }
        else if (this.media.type === 'video') {
          return '';
        }
        else if (this.media.type === 'rich') {
          return this.media.description;
        }
      }
      return '';
    },

    icon() {
      let icon = this.media.provider_icon.length ? this.media.provider_icon : 'url';
      return '/assets/img/sprite.svg#' + icon;
    },

    variation() {
      let imageVariation = '';
      let type = '';

      if (this.media.type) {
        type = this.media.type;
      }

      if (!this.media.image.length) {
        imageVariation = ' bubble__embed--no-img';
      }

      return 'bubble__embed--' + type + imageVariation;
    },
  },

}
</script>

<style scoped>
/* Embed */
.bubble__embed {
  display: flex;
  position: relative;
  padding: 20px;
  justify-content: center;
  flex-direction: column;
}

.bubble__embed>* {
  margin-bottom: 10px;
}

.bubble__embed>*:last-child {
  margin-bottom: 0;
}

.bubble__embed-indicator {
  position: absolute;
  padding: 7px;
  margin-bottom: 0;
  right: 100%;
  bottom: 20px;
  width: 30px;
  height: 30px;
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  background: var(--blue);
  fill: #fff;
}

.bubble__embed-image {
  position: relative;
}

.bubble__embed-image img {
  object-fit: contain;
  width: 100%;
}

.bubble__embed-title,
.bubble__embed-description {
  font: 500 1.4rem/1.8rem 'Ubuntu', sans-serif;
}

.bubble__embed--link {
  min-height: 100px;
  padding-left: 140px;
}

.bubble__embed--no-img {
  padding-left: 20px;
}

.bubble__embed--link .bubble__embed-image {
  position: absolute;
  top: 20px;
  left: 20px;
  width: 100px;
  height: 60px;
  margin-bottom: 0;
}

.bubble__embed--link .bubble__embed-image img {
  height: 100%;
}

.bubble__embed--link .bubble__embed-title {
  margin-bottom: 5px;
  font: 400 1rem/1.6rem 'Ubuntu', sans-serif;
  color: #6a6a6a;
}

.bubble__text+.bubble__embed {
  background: linear-gradient(to bottom, rgba(11, 52, 217, 0.1), transparent);
}

.bubble__text+.bubble__embed--video {
  padding-top: 0;
}
</style>