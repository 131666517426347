<template>
  <div class="modal">
    <div class="embed">
      <h3 class="embed__title">Embed</h3>
      <div class="embed__description">
        You can easily embed and promote LiveCaster on your website.
      </div>

      <div class="embed__preview-wrap">

        <div class="embed__preview">
          <iframe ref="iframe" :src="live_embed_src" :style="{ width: embed_width, height: embed_height }"></iframe>
        </div>

        <div class="embed__details">

          <!--          <label class="embed__input embed__input&#45;&#45;width">-->
          <!--            <input type="number" step="10" placeholder="WIDTH" value="" v-model="width">-->
          <!--          </label>-->

          <label class="embed__input embed__input--height">
            <input type="number" step="10" min="400" placeholder="HEIGHT" v-model="height">
          </label>

          <div class="embed__reset" v-if="values_has_changed" @click="reset">RESET TO DEFAULT</div>
          <div class="embed__copy" v-clipboard:copy="embed_code" v-clipboard:success="onCopy" v-clipboard:error="onError">
            COPY EMBED CODE
          </div>
          <div class="embed__copy" v-clipboard:copy="url" v-clipboard:success="onCopy" v-clipboard:error="onError">
            COPY URL
          </div>

        </div>
      </div>
    </div>
  </div>

  <!-- <div class="modal">
        <div class="pop-up__form">
            <h2 class="pop-up__title">Are you sure?</h2>
            <div class="pop-up__message">
                This action will end your live event.
            </div>
            <span @click="close" class="pop-up__btn pop-up__btn--dismiss">CANCEL</span>
            <span @click.prevent="archive" class="pop-up__btn pop-up__btn--approve">YES</span>
        </div>

    </div> -->
</template>

<script>
// import { closeModal } from 'jenesius-vue-modal'

export default {

  data() {
    return {
      width: 500,
      height: 700,
      embed_src: '#',
      live_embed_src: '#',
    }
  },

  created() {
    this.embed_src = process.env.VUE_APP_EMBED_URL + '/' + this.$route.params.eventId;
    this.live_embed_src = process.env.VUE_APP_EMBED_URL + '/' + this.$route.params.eventId;
  },

  methods: {
    reset: function () {
      this.width = 500;
      this.height = 400;
    },
    onCopy() {
      // console.log('copied');
    },
    onError() {
      // console.log('not copied');
    }
  },

  computed: {
    embed_width() {
      if (this.width > 500) {
        return '500px';
      }
      else if (this.width < 400) {
        return '400px';
      }
      else {
        return this.width + 'px';
      }
    },
    embed_height() {
      return (this.height < 80) ? '80px' : this.height + 'px';
    },
    values_has_changed() {
      return this.width !== 500 || this.height !== 400;
    },
    embed_code() {
      return `<iframe src="${this.embed_src}" width="100%" height="${this.height}"></iframe>`;
    },
    url() {
      return this.embed_src;
    }
  }

}

// export default {

//   data() {
//     return {
//       prevExist: false,
//       nextExist: true,
//     }
//   },

//   components: {
//     // SwiperBox,
//   },

//   methods: {
//     close() {
//       closeModal();
//     },
//     archive() {
//       this.$store.dispatch('stream/archive', this.$route.params.eventId);
//       closeModal();
//     }
//   },

// }
</script>

<style scoped>
.modal {
  max-width: 70%;
}

.embed {
  max-width: 780px;
  margin: 0 auto;
  padding: 30px 20px;
  background: #fff;
  color: #393939;
}

.embed__title {
  text-align: center;
  font: 200 3.2rem/5.5rem 'Ubuntu', sans-serif;
}

.embed__description {
  text-align: center;
  font: 500 1.4rem/2rem 'Ubuntu', sans-serif;
}

.embed__preview-wrap {
  display: flex;
  align-items: flex-start;
  margin-top: 30px;

}

.embed__preview {
  display: flex;
  justify-content: center;
  flex: 1;
  margin-right: 20px;
  max-width: 500px;
  height: 500px;
  overflow: auto;
}

.embed__preview::-webkit-scrollbar {
  width: 5px;
  background: transparent;
}

.embed__preview::-webkit-scrollbar-thumb {
  background: var(--blue);
}

.embed__preview iframe {
  border: 1px solid var(--light-gray);
}

.embed__details {
  display: flex;
  max-width: 220px;
  flex-wrap: wrap;
}

.embed__input {
  position: relative;
  width: 100px;
  height: 50px;
  margin-bottom: 20px;
  border: 1px solid var(--light-gray);
}

.embed__input:first-child {
  margin-right: 20px;
}

.embed__input:after {
  position: absolute;
  top: 100%;
  left: 0;
  margin-top: 5px;
  white-space: nowrap;
  font: 400 1rem/1.4rem 'Ubuntu', sans-serif;
}

.embed__input--width:after {
  content: '*Min. 200 to Max. 500';
}

.embed__input--height:after {
  content: '*Min. 400';
}

.embed__input input {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 10px;
  font: 500 1.2rem/1.7rem 'Ubuntu', sans-serif;
  outline: none;
  border: none;
  /* border: 1px solid green */
}

.embed__input input::placeholder {
  font: 500 1.2rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--gray);
}

.embed__reset {
  margin: 20px 0 0;
  text-decoration: underline;
  font: 500 1.4rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--dark-blue);
  cursor: pointer;
}

.embed__copy {
  width: 100%;
  padding: 15px;
  margin: 20px 0 0;
  text-align: center;
  font: 600 1.4rem/1.6rem 'Ubuntu', sans-serif;
  background: var(--blue);
  color: #fff;
  cursor: pointer;
}
</style>