<template>
  <main>
    <!-- <Board /> -->
    <FeedInput />
    <FeedMain />
    <!-- <AssetsLightbox /> -->
    <widget-container-modal />
  </main>
  <footer>
    <a href="https://www.livecaster.io" title="Livecaster" target="_blank">
      <img src="/assets/img/livecaster-logo-horizontal.svg" width="126" height="24" loading="lazy" alt="Livecaster logo">
    </a>
  </footer>
</template>

<script>
// import Board from './board/Board'
import FeedInput from './feed/FeedInput'
import FeedMain from './feed/FeedMain'
// import AssetsLightbox from '../../components/modals/AssetsLightbox'

import { container } from "jenesius-vue-modal";

export default {

  props: ['eventId'],

  created() {
    this.$store.dispatch('frontStream/fetchStream', this.eventId);
    this.$store.dispatch('frontStream/handlePresence');
  },

  computed: {

  },

  components: {
    // Board,
    FeedInput,
    FeedMain,
    WidgetContainerModal: container,
    // AssetsLightbox,
  },

  beforeUnmount() {
    this.$store.dispatch('frontStream/unsubscribeFromMessages');
  },

}
</script>

<style scoped>
main,
footer {
  max-width: 800px;
  margin: 0 auto;
  background: var(--dark-gray);
  box-shadow: -800px 0 var(--dark-gray), 800px 0 var(--dark-gray);
}

main {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 40px);
  padding-top: 20px;
}

footer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
}
</style>