<template>
  <a :href="link.url" target="_blank" class="preview-link">
    <figure v-if="link.image.length">
      <img :src="link.image" />
    </figure>
    <div class="preview-link__provider">{{ link.provider }}</div>
    <div class="preview-link__title">{{ link.title }}</div>
    <svg @click.prevent="deleteLink">
      <use xlink:href="/assets/img/sprite.svg#close"></use>
    </svg>
  </a>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  methods: {
    deleteLink() {
      this.$store.dispatch('texteditor/resetLink');
    }
  },

  computed: {
    ...mapGetters('texteditor', {
      link: 'link',
    }),
  },

}
</script>

<style scoped>
.preview-link {
  display: block;
  position: relative;
  padding: 0 15px;
  margin: 10px 0;
  min-height: 45px;
  background: #fff;
}

.preview-link:before {
  content: '';
  position: absolute;
  top: -10px;
  left: 10px;
  right: 10px;
  height: 1px;
  background: rgba(0, 0, 0, 0.3);
  pointer-events: none;
}

.preview-link figure {
  float: left;
  margin-right: 10px;
  max-width: 70px;
  height: 45px;
}

.preview-link figure img {
  -o-object-fit: contain;
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.preview-link__provider {
  font-size: 1rem;
  line-height: 1.6rem;
  color: #6a6a6a;
}

.preview-link__title {
  font-size: 1.2rem;
  line-height: 1.7rem;
  font-weight: 600;
  ;
  color: #000;
}

.preview-link svg {
  position: absolute;
  top: calc(50% - 12px);
  right: 15px;
  width: 25px;
  height: 25px;
  padding: 5px;
  border-radius: 50%;
  background: #000;
  fill: #fff;
  z-index: 1;
}

@media only screen and (max-width: 600px) {
  .preview-link {
    margin: 15px 0 10px;
  }
}
</style>