<template>
  <div class="form-group" :class="[{ 'form-group--no-icon': !icon }, isValid]">
    <svg class="icon" v-if="icon">
      <use :xlink:href="'/assets/img/sprite.svg#' + icon"></use>
    </svg>
    <input @keypress="clearField" v-model="value" :type="inputType" :name="name" :placeholder="placeholder"
      :required="isRequired" />
    <span>{{ placeholder }}</span>
  </div>
</template>

<script>
export default {
  props: ['val', 'placeholder', 'name', 'icon', 'required', 'validation'],
  data() {
    return {
      value: this.val,
    };
  },
  methods: {
    clearField() {
      // clear field
    },
  },
  computed: {
    isValid: function () {
      if (this.validation != null) {
        return (this.validation) ? 'valid' : 'invalid';
      }
      return '';
    },
    isRequired() {
      return this.required === 'true';
    },
    inputType() {
      return this.required === 'true' ? 'text' : 'text';
    },
  },
  watch: {
    value() {
      this.$emit('update_val', this.value);
    },
  },
};
</script>
