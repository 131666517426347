<template>
  <div>
    <h1>Create a new event</h1>

    <div class="sessions-description">Create a LIVE Event</div>

    <CreateCard />
  </div>
</template>
          
<script>

import CreateCard from '@/components/events/CreateCard.vue'

export default {

  components: {
    CreateCard
  },

};
</script>
        
<style scoped>
h1 {
  margin-bottom: 20px;
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
}

.sessions-description {
  margin-top: 25px;
  text-align: center;
  color: #fff;
}
</style>