<template>
  <div class="card" :class="cardVariation">
    <div class="card__content">
      <div class="card__info">
        <span>{{ message.user }}</span>
        <span>{{ time }}</span>
        <span>{{ time_ago }}</span>
      </div>
      <div class="card__message">
        {{ message.message }}
      </div>
      <form @submit.prevent="submit" action="post" class="card__form">
        <textarea v-model.trim="reply" :placeholder="placeholder"></textarea>
        <button :class="{ 'disabled': !reply.length }">
          <svg>
            <use xlink:href="/assets/img/sprite.svg#send"></use>
          </svg>
          SEND
        </button>
      </form>
    </div>
    <ul class="card__actions">
      <li @click="accept">Accept</li>
      <li @click="ignore">Ignore</li>
      <li @click="block">Block</li>
    </ul>
  </div>
</template>

<script>
import moment from 'moment'

import { mapGetters } from 'vuex'

export default {

  data() {
    return {
      reply: '',
      interval: null,
      time_ago: '1',
    }
  },

  mounted() {
    this.updateTimeAgo();
    this.interval = setInterval(this.updateTimeAgo, 31 * 1000);
  },

  beforeUnmount: function () {
    clearInterval(this.interval);
  },

  methods: {
    submit() {
      let reply = {
        id: this.message.id,
        reply: this.reply,
      };

      this.$store.dispatch('stream/sendReply', reply);
      this.reply = '';
    },

    accept() {
      this.$store.dispatch('stream/acceptPending');
      this.reply = '';
    },

    ignore() {
      this.$store.dispatch('stream/ignorePending');
      this.reply = '';
    },

    block() {
      this.$store.dispatch('stream/blockPending');
      this.reply = '';
    },

    updateTimeAgo() {
      if (this.message.created) {
        this.time_ago = moment(this.message.created.toDate()).fromNow();
      }
    },
  },

  computed: {
    ...mapGetters('stream', {
      message: 'pendingFirstMessage',
      messagesAmount: 'pendingMessagesAmount',
    }),

    cardVariation() {
      if (this.messagesAmount === 2) {
        return 'card--double';
      }
      else if (this.messagesAmount > 2) {
        return 'card--many';
      }
      return '';
    },

    placeholder() {
      return 'Reply to ' + this.message.user;
    },

    time() {
      if (this.message.created) {
        return moment(this.message.created.toDate()).format('HH:mm');
      } else {
        return 'just now';
      }
    },

  }

}
</script>

<style scoped>
.card {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  position: relative;
  margin-bottom: 40px;
  border-radius: 10px 0 0 10px;
  -webkit-box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.16);
  border-top: 1px solid var(--gray);
  z-index: 1;
}

.card--double:before,
.card--many:before {
  content: '';
  position: absolute;
  height: 8px;
  left: 20px;
  right: 20px;
  top: -10px;
  border-radius: 10px 0 0 0;
  border-top: 1px solid var(--gray);
  background: -o-linear-gradient(left, #fff calc(100% - 100px), var(--gray) 0);
  background: linear-gradient(to right, #fff calc(100% - 100px), var(--gray) 0);
  opacity: 0.8;
}

.card--many:after {
  content: '';
  position: absolute;
  height: 8px;
  left: 40px;
  right: 40px;
  top: -18px;
  border-radius: 10px 0 0 0;
  background: -o-linear-gradient(left, #fff calc(100% - 80px), var(--gray) 0);
  background: linear-gradient(to right, #fff calc(100% - 80px), var(--gray) 0);
  opacity: 0.6;
}

.card__content {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #fff;
  border-radius: 10px 0 0 10px;
  ;
}

.card__info {
  padding: 20px;
  font-weight: bold;
  color: #000;
}

.card__info span {
  margin-right: 20px;
}

.card__info span:nth-child(3) {
  position: relative;
  color: var(--blue);
}

.card__info span:nth-child(3):before {
  content: '';
  position: absolute;
  top: 0;
  bottom: 0;
  left: -10px;
  width: 1px;
  background: rgba(0, 0, 0, 0.2);
}

.card__message {
  height: 100px;
  margin: 0 20px 10px;
  overflow-y: auto;
  font-size: 1.6rem;
  line-height: 2.2rem;
  color: #000;
}

.card__message::-webkit-scrollbar {
  width: 4px;
}

.card__message::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.3);
}

.card__form {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin-left: 20px;
  padding: 5px 0;
  border-top: 1px solid rgba(0, 0, 0, 0.2);
}

.card__form textarea {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  height: 35px;
  padding: 5px 0;
  resize: none;
  outline: none;
  border: none;
  font: 1.6rem/2rem 'Ubuntu', sans-serif
}

.card__form textarea::-webkit-input-placeholder {
  font: 1.4rem/2rem 'Ubuntu', sans-serif;
}

.card__form textarea::-moz-placeholder {
  font: 1.4rem/2rem 'Ubuntu', sans-serif;
}

.card__form textarea:-ms-input-placeholder {
  font: 1.4rem/2rem 'Ubuntu', sans-serif;
}

.card__form textarea::-ms-input-placeholder {
  font: 1.4rem/2rem 'Ubuntu', sans-serif;
}

.card__form textarea::placeholder {
  font: 1.4rem/2rem 'Ubuntu', sans-serif;
}

.card__form textarea::-webkit-scrollbar {
  width: 4px;
}

.card__form textarea::-webkit-scrollbar-thumb {
  border-radius: 50px;
  background: rgba(0, 0, 0, 0.3);
}

.card__form button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin: 0 20px 0 10px;
  padding: 0 10px;
  background: none;
  outline: none;
  cursor: pointer;
  border: none;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: bold;
  color: var(--blue);
}

.card__form button.disabled {
  color: var(--light-gray);
  pointer-events: none;
}

.card__form button svg {
  margin-right: 5px;
  width: 20px;
  height: 17px;
  fill: var(--blue);
}

.card__form button.disabled svg {
  fill: var(--light-gray);
}

.card__actions {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 120px;
  flex: 0 0 120px;
  color: #fff;
  background: var(--gray);
  list-style-type: none;
}

.card__actions li {
  position: relative;
  padding: 5px 20px;
  margin-bottom: 10px;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: 500;
  cursor: pointer;
  z-index: 1;
}

.card__actions li:last-child {
  margin-bottom: 0;
}

.card__actions li:hover:before {
  content: '';
  position: absolute;
  left: -10px;
  right: 10px;
  top: 0;
  bottom: 0;
  background: var(--blue);
  z-index: -1;
  -webkit-box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
}

@media only screen and (max-width: 600px) {
  .card {
    display: block;
    border-radius: 10px 10px 0 0;
  }

  .card--double:before,
  .card--many:before,
  .card--many:after {
    border-radius: 10px 10px 0 0;
    background: #fff;
  }

  .card__info {
    padding: 15px 10px;
  }

  .card__message {
    margin: 0 10px 10px;
  }

  .card__form {
    margin-left: 10px;
  }

  .card__form button {
    margin: 0 10px;
  }

  .card__content {
    border-radius: 10px 10px 0 0;
  }

  .card__actions {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
    -ms-flex-direction: row;
    flex-direction: row;
    border-radius: 0 0 10px 10px;
  }

  .card__actions li {
    margin: 5px 10px;
    padding: 10px;
  }

  .card__actions li:hover:before {
    display: none;
  }
}
</style>
