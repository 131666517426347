<template>
  <swiper :modules="modules" :slides-per-view="1" :space-between="50" navigation :pagination="{ clickable: true }"
    class="lightbox" :scrollbar="{ draggable: true }">
    <SwiperSlide class="lightbox__slide" v-for="(image, index) in images" :key="index">
      <figure><img :src="imageUrl(image)"></figure>
    </SwiperSlide>
  </swiper>
</template>

<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation, Pagination, Scrollbar } from 'swiper/modules';

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';

export default {

  props: ['images'],

  components: {
    Swiper,
    SwiperSlide,
  },

  setup() {
    return {
      modules: [Navigation, Pagination, Scrollbar],
    };
  },

  methods: {
    imageUrl(image) {
      return image.startsWith('http') ? image : 'https://st1.livecaster.io/lc1/' + image;
    },
  },

};
</script>