import axios from 'axios'
import { db } from '@/firebase'
import { getDatabase, ref, onValue } from 'firebase/database'
import { serverTimestamp, Timestamp } from 'firebase/firestore'
import router from '@/router';

const state = function () {
  return {
    eventId: null,
    info: {
      status: null,
      title: '',
      date: '',
      match: {
        exist: false,
        type: null,
      },
    },
    event: {},
    connectedUsers: 0,
    messagesCount: 0,
    messages: [],
    pendingMessages: [],
    ignoredMessages: [],
    blockedMessages: [],
    streamFilters: {
      highlights: false,
      host: false,
    },
    streamTab: 'live',
    helpers: {
      nextPage: null,
      nextPageBusy: false,
    },
    menuItems: {
      create: true,
      archive: false,
      embed: false,
    },
    isFetchingMessages: false,
    lastVisibleMessage: null,
  }
}

const getters = {
  messagesAmount(state) {
    return state.messagesCount;
  },

  pendingFirstMessage(state) {
    return state.pendingMessages[0];
  },

  pendingLatestMessage(state) {
    return state.pendingMessages[state.pendingMessages.length - 1];
  },

  pendingMessagesAmount(state) {
    return state.pendingMessages.length;
  },

  streamFilterFlags(state) {
    return state.streamFilters;
  },

  streamInfo(state) {
    return state.info;
  },

  event(state) {
    return state.event;
  },

  activeStreamTab(state) {
    return state.streamTab;
  },

  onlineUsers(state) {
    return state.connectedUsers;
  },

  liveMessages(state) {
    let messages = state.messages;

    if (state.streamFilters.highlights && state.streamFilters.host) {
      messages = messages.filter(function (msg) {
        return msg.highlight === true && msg.guest === false;
      });
    }
    else {
      if (state.streamFilters.highlights) {
        messages = messages.filter(function (msg) {
          return msg.highlight === true;
        });
      }
      if (state.streamFilters.host) {
        messages = messages.filter(function (msg) {
          return msg.guest === false;
        });
      }
    }

    return messages;
  },

  ignoredMessages(state) {
    return state.ignoredMessages;
  },

  blockedMessages(state) {
    return state.blockedMessages;
  },

  userPermission(state) {
    return state.event.userMessages;
  },

  match(state) {
    return state.info.match;
  },

  menu(state) {
    return state.menuItems;
  },
}

const actions = {

  async fetchStream({ commit, dispatch }, eventId) {
    commit('SET_EVENT_ID', eventId);

    try {
      const eventRef = db.collection('events').doc(eventId);

      // Listen for real-time changes to the event document
      eventRef.onSnapshot(async (eventDoc) => {
        if (eventDoc.exists) {
          const eventData = eventDoc.data();
          commit('SET_STREAM_INFO', eventData);
          commit('SET_EVENT', eventData);

          if (eventData.status == -1) {
            commit('SET_MENU_ITEMS', { archive: false, create: false, embed: true });
          }
          else {
            commit('SET_MENU_ITEMS', { archive: true, create: false, embed: true });
          }

          if (eventData.archived === true) {
            try {
              const response = await axios.get(`https://st1.livecaster.io/lc-events/events/${eventId}.json`);

              const messages = [];
              response.data.messages.forEach((messageData) => {
                messageData.created = new Timestamp(messageData.created._seconds, messageData.created._nanoseconds);
                if (messageData.updated) {
                  messageData.updated = new Timestamp(messageData.updated._seconds, messageData.updated._nanoseconds);
                }
                messages.push(messageData);
              });

              commit('SET_STREAM_MESSAGES', messages);
            } catch (axiosError) {
              console.error('Error fetching archived event:', axiosError);
            }
          }
          else {
            await dispatch('fetchMessages', eventId);
          }
        }
      });

    } catch (error) {
      console.error('Error fetching event:', error);
      throw error;
    }
  },

  async countMessages({ commit }, eventId) {
    try {
      const eventRef = db.collection('events').doc(eventId);
      const messagesRef = eventRef.collection('messages');

      const querySnapshot = await messagesRef.where('status', '==', 1).get();

      const count = querySnapshot.size;

      commit('UPDATE_MESSAGE_COUNT', count);
    } catch (error) {
      console.error('Error counting messages:', error);
    }
  },

  async fetchMessages({ commit, dispatch }, eventId) {

    try {
      // Call countMessages using dispatch to get the total message count
      await dispatch('countMessages', eventId);

      const eventRef = db.collection('events').doc(eventId);
      const messagesRef = eventRef.collection('messages');

      // Add a query to order messages by date in descending order
      const query = messagesRef.where('status', '==', 1).orderBy('created', 'desc');

      // Use the onSnapshot method to listen for real-time changes
      query.onSnapshot((querySnapshot) => {
        const messages = [];

        querySnapshot.forEach((doc) => {
          // Get the message data and its ID
          const messageData = doc.data();
          const messageId = doc.id;

          // Add the message data and ID to the messages array
          messages.push({ id: messageId, ...messageData });
        });

        // Update the Vuex store with the latest messages
        commit('SET_STREAM_MESSAGES', messages);
      });
    } catch (error) {
      console.error('Error getting messages:', error);
    }
  },

  // async fetchMessages({ commit, dispatch, state }, { eventId, nextPage = false }) {
  //   if (state.isFetchingMessages && nextPage) {
  //     console.log("fetchMessages is already running.");
  //     return;
  //   }

  //   state.isFetchingMessages = true;

  //   try {
  //     await dispatch('countMessages', eventId);

  //     const eventRef = db.collection('events').doc(eventId);
  //     const messagesRef = eventRef.collection('messages');

  //     let query = messagesRef.where('status', '==', 1)
  //       .orderBy('created', 'desc')
  //       .limit(50);

  //     if (nextPage && state.lastVisibleMessage) {
  //       query = query.startAfter(state.lastVisibleMessage);
  //       console.log('nop')
  //     }
  //     console.log(nextPage)

  //     if (!nextPage) {
  //       console.log('μεσα;')
  //       // Use onSnapshot for real-time updates on the initial fetch

  //       query.onSnapshot((querySnapshot) => {
  //         const messages = [];

  //         querySnapshot.forEach((doc) => {
  //           // Get the message data and its ID
  //           const messageData = doc.data();
  //           const messageId = doc.id;

  //           // Add the message data and ID to the messages array
  //           messages.push({ id: messageId, ...messageData });
  //         });

  //         // Update the Vuex store with the latest messages
  //         commit('SET_STREAM_MESSAGES', messages);
  //       });



  //       // query.onSnapshot((querySnapshot) => {
  //       //   const messages = [];
  //       //   querySnapshot.forEach((doc) => {
  //       //     const messageData = doc.data();
  //       //     const messageId = doc.id;
  //       //     messages.push({ id: messageId, ...messageData });
  //       //     console.log(doc)
  //       //   });
  //       //   console.log(messages)

  //       //   if (messages.length > 0) {
  //       //     commit('SET_STREAM_MESSAGES', messages);
  //       //     commit('SET_LAST_VISIBLE_MESSAGE', querySnapshot.docs[querySnapshot.docs.length - 1]);
  //       //   }
  //       // });
  //     } else {
  //       // Use get for pagination (next page)
  //       const querySnapshot = await query.get();
  //       const messages = [];
  //       querySnapshot.forEach((doc) => {
  //         const messageData = doc.data();
  //         const messageId = doc.id;
  //         messages.push({ id: messageId, ...messageData });
  //       });


  //       if (messages.length > 0) {
  //         commit('SET_STREAM_MESSAGES', messages);
  //         commit('SET_LAST_VISIBLE_MESSAGE', querySnapshot.docs[querySnapshot.docs.length - 1]);
  //       }
  //     }
  //   } catch (error) {
  //     console.error('Error getting messages:', error);
  //   } finally {
  //     if (nextPage) {
  //       state.isFetchingMessages = false;
  //     }
  //   }
  // },

  fetchMoreMessages({ commit, state }) {
    if (state.helpers.nextPage !== null) {
      commit('SET_NEXT_PAGE_STATUS', true);

      axios.get(state.helpers.nextPage)
        .then(function (response) {
          if (response.data) {

            commit('ADD_MORE_MESSAGES', response.data);
            commit('SET_NEXT_PAGE_STATUS', false);
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    }
  },

  streamTab({ state, commit }, tab) {
    if (tab !== state.streamTab) {
      if (tab === 'ignored') {
        try {
          const eventRef = db.collection('events').doc(state.eventId);
          const messagesRef = eventRef.collection('messages');

          // Add a query to order messages by date in descending order
          const query = messagesRef.where('status', '==', -1).orderBy('created', 'desc');

          // Use the onSnapshot method to listen for real-time changes
          query.onSnapshot((querySnapshot) => {
            const messages = [];

            querySnapshot.forEach((doc) => {
              // Get the message data and its ID
              const messageData = doc.data();
              const messageId = doc.id;

              // Add the message data and ID to the messages array
              messages.push({ id: messageId, ...messageData });
            });

            // Update the Vuex store with the latest messages
            commit('SET_STREAM_IGNORED_MESSAGES', messages);
          });
        } catch (error) {
          console.error('Error getting messages:', error);
        }
      }
      else if (tab === 'blocked') {
        try {
          const eventRef = db.collection('events').doc(state.eventId);
          const messagesRef = eventRef.collection('messages');

          // Add a query to order messages by date in descending order
          const query = messagesRef.where('status', '==', -2).orderBy('created', 'desc');

          // Use the onSnapshot method to listen for real-time changes
          query.onSnapshot((querySnapshot) => {
            const messages = [];

            querySnapshot.forEach((doc) => {
              // Get the message data and its ID
              const messageData = doc.data();
              const messageId = doc.id;

              // Add the message data and ID to the messages array
              messages.push({ id: messageId, ...messageData });
            });

            // Update the Vuex store with the latest messages
            commit('SET_STREAM_BLOCKED_MESSAGES', messages);
          });
        } catch (error) {
          console.error('Error getting messages:', error);
        }
      }
      commit('SET_STREAM_TAB', tab);
    }
  },

  fetchPendingMessages({ commit }, eventId) {
    try {

      const eventRef = db.collection('events').doc(eventId);
      const messagesRef = eventRef.collection('messages');

      // Add a query to order messages by date in descending order
      const query = messagesRef.where('status', '==', 0).orderBy('created', 'asc');

      // Use the onSnapshot method to listen for real-time changes
      query.onSnapshot((querySnapshot) => {
        const messages = [];

        querySnapshot.forEach((doc) => {
          // Get the message data and its ID
          const messageData = doc.data();
          const messageId = doc.id;

          // Add the message data and ID to the messages array
          messages.push({ id: messageId, ...messageData });
        });

        // Update the Vuex store with the latest messages
        commit('SET_PENDING_MESSAGES', messages);
      });
    } catch (error) {
      console.error('Error getting messages:', error);
    }
  },

  async sendReply({ state }, msg) {
    try {

      const eventRef = db.collection('events').doc(state.eventId);
      const messagesCollection = eventRef.collection('messages');

      const messageDoc = await messagesCollection.doc(msg.id).get();

      const timestamp = serverTimestamp();

      if (messageDoc.exists) {
        await messageDoc.ref.update({
          status: 1,
          updated: timestamp,
          reply: msg.reply,
        });

      } else {
        console.error("Message not found");
      }
    } catch (error) {
      console.error("Error updating message:", error);
    }
  },

  async acceptPending({ state }) {
    try {
      let msg = state.pendingMessages[0];

      const eventRef = db.collection('events').doc(state.eventId);
      const messagesCollection = eventRef.collection('messages');

      const messageDoc = await messagesCollection.doc(msg.id).get();

      const timestamp = serverTimestamp();

      if (messageDoc.exists) {
        await messageDoc.ref.update({
          status: 1,
          updated: timestamp,
        });

      } else {
        console.error("Message not found");
      }
    } catch (error) {
      console.error("Error updating message:", error);
    }
  },

  async ignorePending({ state }) {
    try {
      let msg = state.pendingMessages[0];

      const eventRef = db.collection('events').doc(state.eventId);
      const messagesCollection = eventRef.collection('messages');

      const messageDoc = await messagesCollection.doc(msg.id).get();

      const timestamp = serverTimestamp();

      if (messageDoc.exists) {
        await messageDoc.ref.update({
          status: -1,
          updated: timestamp,
        });

      } else {
        console.error("Message not found");
      }
    } catch (error) {
      console.error("Error updating message:", error);
    }
  },

  async blockPending({ state }) {
    try {

      let msg = state.pendingMessages[0];

      const eventRef = db.collection('events').doc(state.eventId);
      eventRef.collection('blockedUsers').doc(msg.uid).set({ blocked: true });

      const messagesCollection = eventRef.collection('messages');
      const querySnapshot = await messagesCollection.where('uid', '==', msg.uid)
        .where('status', '==', 0)
        .where('guest', '==', true)
        .get();

      const timestamp = serverTimestamp();

      let updates = [];
      querySnapshot.forEach(doc => {
        updates.push(doc.ref.update({
          status: -2,
          updated: timestamp,
        }));
      });

      await Promise.all(updates);

    } catch (error) {
      // console.error("Error updating message:", error);
    }
  },

  async togglePermission({ state }, eventId) {
    try {
      const eventRef = db.collection('events').doc(eventId);
      let permission = !state.event.userMessages;
      await eventRef.update({
        userMessages: permission,
      });
    } catch (error) {
      console.error("Error updating comments status:", error);
    }
  },

  async deleteMessage({ commit }, msg) {
    try {
      const eventRef = db.collection('events').doc(msg.eventId);
      const messagesCollection = eventRef.collection('messages');
      const messageRef = messagesCollection.doc(msg.id);

      await messageRef.delete();
      commit('DELETE_MESSAGE', msg.id);
    } catch (error) {
      console.error("Error deleting message:", error);
    }
  },

  async highlightMessage(_, msg) {
    try {
      const eventRef = db.collection('events').doc(msg.eventId);
      const messagesCollection = eventRef.collection('messages');

      const messageDoc = await messagesCollection.doc(msg.id).get();

      if (messageDoc.exists) {
        await messageDoc.ref.update({
          highlight: !msg.highlight
        });

      } else {
        console.error("Message not found");
      }
    } catch (error) {
      console.error("Error updating message highlight:", error);
    }
  },

  filterMessages({ commit }, term) {
    commit('FILTER_STREAM', term);
  },

  async fetchOnlineUsers({ state, commit }) {
    const db = getDatabase();
    const onlineUsersRef = ref(db, 'events/' + state.eventId + '/onlineUsers');
    const unsubscribe = onValue(onlineUsersRef, (snapshot) => {
      const onlineUsersData = snapshot.val();
      if (onlineUsersData) {
        const onlineUserCount = Object.keys(onlineUsersData).length;
        commit('STREAM_STATS', onlineUserCount);
      }
      else {
        commit('STREAM_STATS', 0);
      }
    }, (error) => {
      console.error("Error fetching online users:", error);
    });

    return unsubscribe;
  },

  async archive(_, eventId) {
    try {
      const eventRef = db.collection('events').doc(eventId);
      await eventRef.update({ status: -1, archived: false });

      router.push('/dashboard')
    } catch (error) {
      console.error('Error:', error);
    }
  },

  clearEventData({ commit }) {
    commit('CLEAR_EVENT_DATA');
  },

}

const mutations = {

  SET_EVENT_ID(state, eventId) {
    state.eventId = eventId;
  },

  SET_STREAM_INFO(state, data) {
    state.info.title = data.title;
    state.info.date = data.created_at;
    state.info.status = data.status;
    state.info.userMessages = data.userMessages;
    state.info.match = {
      exist: data.is_sport,
      type: data.sport,
    };
  },

  SET_EVENT(state, data) {
    state.event = data;
  },

  SET_STREAM_MESSAGES(state, data) {
    // state.messages = [...data, ...state.messages];
    state.messages = data;
  },

  SET_LAST_VISIBLE_MESSAGE(state, data) {
    state.lastVisibleMessage = data;
  },

  SET_STREAM_IGNORED_MESSAGES(state, messages) {
    state.ignoredMessages = messages;
  },

  SET_STREAM_BLOCKED_MESSAGES(state, messages) {
    state.blockedMessages = messages;
  },

  SET_STREAM_TAB(state, tab) {
    state.streamTab = tab;
  },

  SET_PENDING_MESSAGES(state, messages) {
    state.pendingMessages = messages;
  },

  SET_NEXT_PAGE_STATUS(state, val) {
    state.helpers.nextPageBusy = val;
  },

  FILTER_STREAM(state, term) {
    switch (term) {
      case 'highlights':
        state.streamFilters.highlights = (state.streamFilters.highlights) ? false : true;
        break;
      case 'host':
        state.streamFilters.host = (state.streamFilters.host) ? false : true;
        break;
    }
  },

  ADD_MESSAGE(state, msg) {
    state.messages.unshift(msg);
  },

  UPDATE_MESSAGE_COUNT(state, amount) {
    state.messagesCount = amount;
  },

  ADD_MORE_MESSAGES(state, data) {
    data.messages.forEach(msg => {
      let pos = state.messages.findIndex(el => el.id === msg.id);

      if (pos != -1) {
        state.messages[pos] = msg;
      }
      else {
        state.messages.push(msg);
      }
    });

    state.helpers.nextPage = data.next_page;
  },

  ADD_PENDING_MESSAGE(state, msg) {
    state.pendingMessages.push(msg);
  },

  STREAM_STATS(state, count) {
    state.connectedUsers = count;
  },

  HIGHLIGHT_MESSAGE(state, id) {
    for (let i = 0; i < state.messages.length; i++) {
      if (state.messages[i].id === id) {
        state.messages[i].highlight = state.messages[i].highlight ? 0 : 1;
        break;
      }
    }
  },

  DELETE_MESSAGE(state, id) {
    for (let i = 0; i < state.messages.length; i++) {
      if (state.messages[i].id === id) {
        state.messages.splice(i, 1);
        break;
      }
    }
  },

  ACCEPT_PENDING_MESSAGE(state) {
    state.pendingMessages.shift();
  },

  IGNORE_PENDING_MESSAGE(state) {
    let msg = state.pendingMessages[0];

    msg.ignore = true;
    state.ignoredMessages.unshift(msg);
    state.pendingMessages.shift();
  },

  CLEAR_EVENT_DATA(state) {
    state.eventId = null;
    state.info = {
      status: null,
      title: '',
      date: '',
      match: {
        exist: false,
        type: null,
      },
    };
    state.event = {};
    state.connectedUsers = 0;
    state.messagesCount = 0;
    state.messages = [];
    state.pendingMessages = [];
    state.ignoredMessages = [];
    state.blockedMessages = [];
    state.streamFilters = {
      highlights: false,
      host: false,
    };
    state.streamTab = 'live';
    state.helpers = {
      nextPage: null,
      nextPageBusy: false,
    };
    state.menuItems = {
      create: true,
      archive: false,
      embed: true,
    };
    state.isFetchingMessages = false;
    state.lastVisibleMessage = null;
  },

  SET_MENU_ITEMS(state, data) {
    state.menuItems.create = data.create;
    state.menuItems.archive = data.archive;
    state.menuItems.embed = data.embed;
  }

}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}