<template>
  <div>
    <h1>Welcome to Livecaster, {{ username }}</h1>

    <ul class="sessions-menu" v-if="availableTabs">
      <li @click="tab('active')" :class="{ 'active': activeTab === 'active' }">Active</li>
      <li @click="tab('archive')" :class="{ 'active': activeTab === 'archive' }">Archived</li>
    </ul>

    <div class="sessions-description">
      {{ introMessage }}
    </div>

    <CreateCard v-if="showCreateCard" />

    <div class="sessions">
      <div class="sessions__search" v-if="availableSearch">
        <svg>
          <use xlink:href="/assets/img/sprite.svg#search"></use>
        </svg>
        <input type="text" v-model="searchField" :placeholder="placeholder">
        <svg v-if="searchField.length" @click="searchField = ''">
          <use xlink:href="/assets/img/sprite.svg#close"></use>
        </svg>
      </div>

      <div class="sessions__cards" :class="{ 'sessions__cards--dim': dimCards }">
        <EventCard v-for="event in filteredEvents" :key="event.id" :event="event" @hover="dimCards = true"
          @unhover="dimCards = false" />
        <div ref="trigger"></div>
      </div>
    </div>

  </div>
</template>
          
<script>

import { db, auth } from '@/firebase';

import CreateCard from '@/components/events/CreateCard.vue'
import EventCard from '@/components/events/EventCard.vue'

export default {

  components: {
    CreateCard,
    EventCard,
  },

  data() {
    return {
      activeTab: 'active',
      searchField: '',
      fetchStatus: '',
      dimCards: false,
      liveEvents: [],
      archivedEvents: [],
      pageSize: 24,
      hasMore: true,
      lastDocument: null,
      isLoading: false,
    };
  },

  async mounted() {
    const options = {
      root: null,
      rootMargin: '0px 0px 10px',
      threshold: '0'
    };

    const observer = new IntersectionObserver(this.handleIntersection, options);
    observer.observe(this.$refs.trigger);
  },

  methods: {

    async getEvents() {
      const user = auth.currentUser;
      if (user) {
        if (this.isLoading) return; // Prevent multiple requests
        this.isLoading = true;

        try {
          let query = db.collection('events')
            .where("userId", "==", user.uid)
            .orderBy("created", "desc")
            .limit(this.pageSize);

          if (this.lastDocument) {
            query = query.startAfter(this.lastDocument);
          }

          const snapshot = await query.get();

          if (snapshot.empty) {
            // No more events to load
            this.hasMore = false;
          } else {
            snapshot.docChanges().forEach((change) => { // Process changes
              const event = {
                id: change.doc.id,
                title: change.doc.data().title,
                created: change.doc.data().created,
                status: change.doc.data().status,
              };

              if (event.status >= 0 && event.status <= 2) {
                // Status 0, 1, and 2 are considered live events
                if (change.type === 'added') {
                  // Handle added event
                  this.liveEvents.push(event);
                } else if (change.type === 'modified') {
                  // Handle modified event
                  const index = this.liveEvents.findIndex((e) => e.id === event.id);
                  if (index !== -1) {
                    this.liveEvents[index] = event;
                  }
                } else if (change.type === 'removed') {
                  // Handle removed event
                  const index = this.liveEvents.findIndex((e) => e.id === event.id);
                  if (index !== -1) {
                    this.liveEvents.splice(index, 1);
                  }
                }
              } else if (event.status === -1) {
                // Status -1 is considered an archived event
                if (change.type === 'added') {
                  // Handle added archived event
                  this.archivedEvents.push(event);
                } else if (change.type === 'modified') {
                  // Handle modified archived event
                  const index = this.archivedEvents.findIndex((e) => e.id === event.id);
                  if (index !== -1) {
                    this.archivedEvents[index] = event;
                  }
                } else if (change.type === 'removed') {
                  // Handle removed archived event
                  const index = this.archivedEvents.findIndex((e) => e.id === event.id);
                  if (index !== -1) {
                    this.archivedEvents.splice(index, 1);
                  }
                }
              }
            });

            // Check if there are more items to load
            this.hasMore = !snapshot.empty;

            // Update the lastDocument with the last document from the snapshot
            this.lastDocument = snapshot.docs[snapshot.docs.length - 1];
          }
        } catch (error) {
          console.error("Error fetching events:", error);
        } finally {
          this.isLoading = false;
        }
      }
    },

    handleIntersection(entries) {
      const entry = entries[0];
      if (entry.isIntersecting) {
        this.currentPage++;
        this.getEvents();
      }
    },

    getLastDocument() {
      return this.lastDocument;
    },

    tab(option) {
      this.activeTab = option;
    }

  },

  beforeUnmount() {
    // Unsubscribe from the event listener when the component is unmounted
    if (this.eventListener) {
      this.getEvents();
    }

    if (this.observer) {
      this.observer.disconnect();
    }
  },

  computed: {
    filteredEvents() {
      let that = this;
      let cards = (this.activeTab === 'active') ? this.liveEvents : this.archivedEvents;

      return cards.filter(function (el) {
        return el.title.toLowerCase().includes(that.searchField.toLowerCase());
      });
    },

    placeholder() {
      let val = (this.activeTab === 'active') ? 'active' : 'archived';
      return 'Search ' + val + ' event';
    },

    showCreateCard() {
      return this.activeTab === 'active' && !this.liveEvents.length;
    },

    availableSearch() {
      return (this.activeTab == 'active' && this.liveEvents.length) || (this.activeTab == 'archive' && this.archivedEvents.length) ? true : false;
    },

    availableTabs() {
      return this.liveEvents.length || this.archivedEvents.length;
    },

    introMessage() {
      let message = '';

      if (this.fetchStatus === 'in progress') {
        message = 'Loading events';
      }
      else {
        if (!this.filteredEvents.length && this.liveEvents.length && this.activeTab === 'active') {
          message = 'LIVE event not found';
        }
        else if (!this.filteredEvents.length && this.archivedEvents.length && this.activeTab === 'archive') {
          message = 'Archived event not found';
        }
        else if (this.activeTab === 'active' && !this.liveEvents.length && !this.archivedEvents.length) {
          message = 'Create your first event!';
        }
        else if (this.activeTab === 'active' && !this.liveEvents.length) {
          message = 'Create a LIVE event';
        }
        else if (this.activeTab === 'archived' && !this.archivedEvents.length) {
          message = 'You haven\'t archived any event yet!';
        }
        else {
          message = 'Select an event to join or view';
        }
      }
      return message;
    },

    username() {
      return auth.currentUser.email;//this.user.name.charAt(0).toUpperCase() + this.user.name.slice(1, this.user.name.length);
    },

  }

};
</script>
        
<style scoped>
h1 {
  margin-bottom: 20px;
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
}

.sessions-menu {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 1.8rem;
  line-height: 1.3rem;
  font-weight: 400;
  color: #fff;
  list-style-type: none;
  z-index: 1;
}

.sessions-menu li {
  margin: 0 10px;
  padding-bottom: 5px;
  cursor: pointer;
}

.sessions-menu li.active {
  position: relative;
  font-weight: bold;
  text-transform: uppercase;
}

.sessions-menu li.active:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  bottom: -3px;
  height: 3px;
  background: #fff;
}

.sessions-description {
  margin-top: 20px;
  font-size: 1.5rem;
  line-height: 2rem;
  text-align: center;
  color: #fff;
}

.sessions__search {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 240px;
  padding: 7px 10px;
  margin-bottom: 25px;
  border: 1px solid #fff;
  border-radius: 40px;
}

.sessions__search svg:nth-child(1),
.sessions__search svg:nth-child(3) {
  width: 15px;
  height: 15px;
  -webkit-box-flex: 0;
  -ms-flex: 0 0 15px;
  flex: 0 0 15px;
  fill: #fff;
}

.sessions__search svg:nth-child(3) {
  cursor: pointer;
}

.sessions__search input {
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  margin: 0 10px;
  color: #fff;
  border: none;
  background: transparent;
  outline: none;
}

.sessions__search input::-webkit-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.sessions__search input::-moz-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.sessions__search input:-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.sessions__search input::-ms-input-placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.sessions__search input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.sessions__cards {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  gap: 40px;
}

@media only screen and (max-width: 900px) {
  .sessions-description {
    margin-bottom: 20px;
  }
}

@media only screen and (max-width: 600px) {
  h1 {
    font-size: 2.5rem;
    line-height: 3rem;
  }
}
</style>