<template>
  <div>
    <h1>Edit profile</h1>
    <form @submit.prevent="updateProfile" class="modal">

      <figure v-if="user.photoURL" class="modal__avatar">
        <img :src="user.avatar">
        <span class="modal__avatar-cta">
          <svg>
            <use xlink:href="/assets/img/sprite.svg#photo"></use>
          </svg>
        </span>
      </figure>

      <div v-else class="modal__avatar" :data-initials="initials">
        <!-- <span class="modal__avatar-cta">
          <svg><use xlink:href="/assets/img/sprite.svg#photo"></use></svg>
        </span> -->
      </div>

      <div class="modal__fields">
        <FormText :placeholder="'DISPLAY NAME'" :val="user.displayName" :validation="validation.displayName"
          @update_val="update_displayName" required="true" />

        <input type="submit" value="UPDATE PROFILE" class="modal__submit">

      </div>

    </form>

    <form @submit.prevent="updateEmail" class="modal">
      <h2>Edit email</h2>

      <div class="modal__fields">
        <FormEmail :val="user.email" :validation="validation.email" @update_val="update_email" required="true" />

        <FormPassword name="email_password" :val="email_password" :validation="validation.password"
          @update_val="update_email_password" required="true" />

        <input type="submit" value="UPDATE EMAIL" class="modal__submit">

      </div>

    </form>

    <form @submit.prevent="updatePassword" ref="updatePasswordForm" class="modal">
      <h2>Change password</h2>

      <div class="modal__fields">

        <FormPassword name="password" :placeholder="'PASSWORD'" :val="password" :validation="validation.password"
          @update_val="update_password" required="true" />

        <FormPassword name="new_password" :placeholder="'NEW PASSWORD'" :val="new_password"
          :validation="validation.new_password" @update_val="update_new_password" required="true" />

        <FormPassword name="new_password_corfirm" :placeholder="'CONFIRM NEW PASSWORD'" :val="new_password_confirm"
          :validation="validation.new_password_corfirm" @update_val="update_new_password_confirm" required="true" />

        <input type="submit" value="UPDATE PASSWORD" class="modal__submit">

      </div>

    </form>
  </div>
</template>

<script>

import { firebase, auth } from '@/firebase';
import FormText from '@/components/forms/FormText.vue';
import FormEmail from '@/components/forms/FormEmail.vue';
import FormPassword from '@/components/forms/FormPassword.vue'

export default {
  components: {
    FormText,
    FormEmail,
    FormPassword,
  },

  data() {
    return {
      user: auth.currentUser,
      displayName: '',
      email: '',
      password: '',
      email_password: '',
      new_password: '',
      new_password_confirm: '',
      errors: '',
      validation: {
        displayName: null,
        email: null,
        password: null,
        new_password: null,
        new_password_confirm: null,
      },
    };
  },

  computed: {
    initials() {
      return this.user.displayName ? this.user.displayName.trim().substring(0, 2).toLocaleUpperCase() : '';
    },
  },

  methods: {
    async updateProfile(event) {
      event.preventDefault();
      try {
        await this.user.updateProfile({
          displayName: this.displayName,
        });
      } catch (error) {
        console.error('Profile update error', error);
      }
    },

    async updateEmail(event) {
      event.preventDefault();

      try {
        const user = firebase.auth().currentUser;
        const currentPassword = this.email_password;

        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
        await user.reauthenticateWithCredential(credentials);

        await user.updateEmail(this.email);

        this.email_password = '';
      } catch (error) {
        console.error('Email update error', error);
      }
    },

    async updatePassword(event) {
      event.preventDefault();

      try {
        const user = firebase.auth().currentUser;
        const currentPassword = this.password;

        const credentials = firebase.auth.EmailAuthProvider.credential(user.email, currentPassword);
        await user.reauthenticateWithCredential(credentials);

        user.updatePassword(this.new_password);

        this.$refs.updatePasswordForm.reset();

        console.log('Password updated successfully');
      } catch (error) {
        console.error('Password update error', error);
      }
    },

    validateForm: function () {
      let displayName = this.user.displayName.trim();
      let email = this.user.email.trim();
      let password = this.user.password.trim();

      this.validation.displayName = (displayName.length < 2) ? false : true;
      this.validation.email = (email.length < 4) ? false : true;
      this.validation.password = (password.length < 4) ? false : true;

      return this.validation.displayName && this.validation.email && this.validation.password;
    },

    validatePasswordForm: function () {
      let new_password = this.new_password.trim();
      let new_password_confirm = this.new_password_confirm.trim();

      this.validation.new_password = (new_password.length < 4) ? false : true;
      this.validation.new_password_confirm = new_password !== new_password_confirm;

      return this.validation.new_password && this.validation.new_password_confirm;
    },

    update_displayName: function (val) {
      this.displayName = val;
    },

    update_email: function (val) {
      this.email = val;
    },

    update_password: function (val) {
      this.password = val;
    },

    update_email_password: function (val) {
      this.email_password = val;
    },

    update_new_password: function (val) {
      this.new_password = val;
    },

    update_new_password_confirm: function (val) {
      this.new_password_confirm = val;
    },

  },

  async mounted() {
    // Fetch the user's data and populate the form fields
    if (this.user) {
      this.user = auth.currentUser;
    }
  },

};

</script>

<style scoped>
.modal__avatar {
  position: relative;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
}

div.modal__avatar {
  background: var(--turquoise);
}

div.modal__avatar:before {
  content: attr(data-initials);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.4rem;
  line-height: 4rem;
  font-weight: bold;
  color: #fff;
}

.modal__avatar img {
  border-radius: 50%;
}

.modal__avatar-cta {
  position: absolute;
  padding: 5px;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  background: #000;
  fill: #fff;
  border-radius: 50%;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.29);
  cursor: pointer;
}

.modal__avatar svg {
  fill: #fff;
  position: absolute;
  top: 5px;
  left: 5px;
  width: 20px;
  height: 20px;
}

.modal__avatar svg:before {
  content: '';
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  height: 50px;
  background: red;
}

.modal__fields {
  display: flex;
  flex-wrap: wrap;
  margin: 0 -5px;
}

.form-group {
  margin: 0 5px 20px;
}

.modal__submit {
  max-width: calc(100% - 10px);
  margin: 0 auto;
  width: 100%;
  padding: 17px;
  text-align: center;
  background: var(--blue);
  color: #fff;
  cursor: pointer;
  border: none;
  outline: none;
  font: bold 1.4rem/1.6rem 'Ubuntu', sans-serif;
}
</style>