<template>
  <header>
    <a href="/dashboard" @click.prevent="$router.push('/dashboard')" title="Livecaster" class="logo">
      <img src="/assets/img/livecaster-logo-horizontal.svg" alt="Livecaster logo">
    </a>

    <ul class="menu" v-if="menu.create || menu.archive || menu.embed">
      <li v-if="menu.create">
        <a href="/create" @click.prevent="$router.push('/create')" class="bubble-button bubble-button--purple">
          Create new event
        </a>
      </li>

      <li v-if="menu.archive">
        <a href="/create" @click.prevent="showArchiveEvent" class="bubble-button bubble-button--purple">
          Archive event
        </a>
      </li>

      <li v-if="menu.embed">
        <a href="#" @click.prevent="getEmbedCode" class="bubble-button bubble-button--purple">
          Embed code
        </a>
      </li>
    </ul>

    <UserMenu />

    <div class="notifications">
      <NotificationPrompt v-if="false" :title="'Success'" :body="'Congratulations'" :type="'success'" />
      <!-- <NotificationPrompt :title="'Success'" :body="'Congratulations'" :type="'warning'" />
      <NotificationPrompt :title="'Success'" :body="'Congratulations'" :type="'info'" />
      <NotificationPrompt :title="'Success'" :body="'Congratulations'" :type="'error'" /> -->
    </div>

  </header>

  <main>
    <router-view />
  </main>

  <footer>
    <a href="https://www.pixual.co" target="_blank">
      <img src="/assets/img/pixual-logo.svg" alt="Pixual">
    </a>
  </footer>
  <widget-container-modal />
</template>
        
<script>

import { mapGetters } from 'vuex'
import UserMenu from '@/components/menus/UserMenu.vue'
import NotificationPrompt from '@/components/misc/NotificationPrompt.vue'
import { container, openModal } from "jenesius-vue-modal"
import ArchiveEvent from '@/components/misc/modals/ArchiveEvent'
import EmbedEvent from '@/components/misc/modals/EmbedEvent'

export default {

  computed: {
    ...mapGetters('stream', {
      menu: 'menu',
    }),
  },

  components: {
    UserMenu,
    NotificationPrompt,
    WidgetContainerModal: container,
  },

  methods: {
    showArchiveEvent() {
      openModal(ArchiveEvent);
    },
    getEmbedCode() {
      openModal(EmbedEvent);
    },
  },

  data() {
    return {

    };
  },

};
</script>

<style>
.modal-container {
  z-index: 2;
  background-color: rgba(0, 0, 0, 0.5);
}
</style>
      
<style scoped>
body {
  background: var(--dark-gray);
}

header {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  background: var(--dark-blue);
  z-index: 2;
}

.logo {
  position: absolute;
  left: 10px;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
}

.logo img {
  width: 160px;
}

.menu {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  list-style-type: none;
}

.menu:before {
  content: '';
  position: absolute;
  left: -40px;
  right: -40px;
  bottom: 0;
  top: 0;
  background: linear-gradient(to right, var(--dark-blue) 0, var(--blue) 25% 75%, var(--dark-blue));
}

.menu li {
  position: relative;
  display: flex;
  align-items: center;
  height: 100%;
  margin: 0 15px;
  font-size: 1.6rem;
  line-height: 2rem;
  color: #fff;
}

.menu li>* {
  cursor: pointer;
}

.menu li>*:after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 50%;
  height: 3px;
  transform: translateX(-50%);
  background: linear-gradient(to right, var(--light-blue), var(--purple));
  transition: 0.2s;
}

.menu li>*.is-active:after {
  width: 100%;
}

main {
  position: relative;
  min-height: calc(100vh - 50px);
  margin: 0 auto;
  padding: 90px 10px 0;
  background: var(--dark-gray);
  box-shadow: -800px 0 var(--dark-gray), 800px 0 var(--dark-gray);
  z-index: 1;
  max-width: 980px;
}

main:before {
  content: '';
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  height: 330px;
  background: linear-gradient(to bottom, var(--dark-blue), var(--blue));
  z-index: -1;
  ;
}

footer {
  padding: 15px 0;
  height: 50px;
  text-align: center;
  background: var(--dark-gray);
}

.bubble-button {
  padding: 11px 14px;
  border-radius: 0;
  min-width: 120px;
  font-size: 1.3rem;
  line-height: 1.4rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background: linear-gradient(to right, var(--fuchsia), var(--orange));
  cursor: pointer;
  transition: border-radius 0.2s;
}

.bubble-button--purple {
  background: linear-gradient(to right, var(--light-blue), var(--purple));
}

.bubble-button--gray {
  background: #848484;
}

.notifications {
  position: fixed;
  top: 60px;
  right: 10px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  z-index: 1;
  overflow: hidden;
}

@media (hover: hover) {
  .bubble-button:hover {
    border-radius: 8px;
  }
}

@media only screen and (max-width: 768px) {
  .menu {
    position: fixed;
    bottom: 0;
    left: 0;
    height: auto;
    padding: 10px;
    right: 0;
    justify-content: center;
    background: var(--gray);
  }

  .menu:before {
    display: none;
  }

  .menu li>*:after {
    display: none;
  }

  .bubble-button {
    min-width: auto;
  }
}

@media only screen and (max-width: 600px) {
  footer {
    padding-bottom: 80px;
  }
}
</style>