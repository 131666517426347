<template>
  <article @mouseout="$emit('unhover')" @mouseover="$emit('hover')" class="card"
    :class="{ 'card--ended': event.status === -1 }">
    <router-link :to="{ name: 'event', params: { eventId: event.id } }" class="card__link">
      <div class="card__info">
        <span>{{ time }}</span>
        <span>{{ status }}</span>
      </div>
      <h2>{{ event.title }}</h2>
      <span v-if="event.status === 1" class="card__button">JOIN</span>
      <span v-else class="card__button">VIEW</span>
    </router-link>
  </article>
</template>

<script>

import moment from 'moment';

export default {

  props: ['event'],

  computed: {
    status() {
      let status = '';
      switch (this.event.status) {
        case -1:
          status = 'ARCHIVED';
          break;
        case 0:
          status = 'PLANNED';
          break;
        case 1:
          status = 'ACTIVE';
          break;

        case 2:
          status = 'ENDED';
          break;
      }
      return status;
    },

    time() {
      if (this.event.created) {
        return moment(this.event.created.toDate()).format('DD.MM.YYYY');
      } else {
        return 'just now';
      }
    }
  },

}
</script>

<style scoped>
.card {
  max-width: calc(33.333% - 40px);
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
  background: #fff;
}

.card__link {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  height: 100%;
  padding: 15px 20px 0;
}

.card__link:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: transparent;
  -webkit-transition: 0.5s;
  -o-transition: 0.5s;
  transition: 0.5s;
}

.sessions__cards--dim .card__link:hover:before {
  background: transparent;
}

.sessions__cards--dim .card__link:before {
  background: rgba(0, 0, 0, 0.7);
}

.card__link h2 {
  margin: 10px 0 20px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 2.4rem;
  line-height: 3rem;
  color: var(--dark-gray);
}

.card__info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}

.card__info span:nth-child(1) {
  font-size: 1.4rem;
  line-height: 1.7rem;
  color: var(--dark-gray);
}

.card__info span:nth-child(2) {
  padding: 5px 15px;
  border-radius: 40px;
  color: #fff;
  background: -webkit-gradient(linear, left top, left bottom, from(var(--fuchsia)), to(var(--orange)));
  background: -o-linear-gradient(top, var(--fuchsia), var(--orange));
  background: linear-gradient(to bottom, var(--fuchsia), var(--orange));
}

.card__button {
  margin: 0 -20px;
  padding: 15px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: bold;
  text-align: center;
  color: #fff;
  background: -webkit-gradient(linear, left top, right top, from(var(--light-blue)), to(var(--purple)));
  background: -o-linear-gradient(left, var(--light-blue), var(--purple));
  background: linear-gradient(to right, var(--light-blue), var(--purple));
}

.card--ended .card__info span:nth-child(2) {
  background: var(--gray);
}

.card--ended .card__button {
  background: var(--light-blue);
}

@media only screen and (max-width: 900px) {
  .card {
    max-width: calc(50% - 40px);
  }
}

@media only screen and (max-width: 600px) {
  .card {
    max-width: none;
    margin: 0 0 20px;
  }
}
</style>