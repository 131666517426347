<template>
  <ul class="tabs">
    <li @click="tab('text')">
      <svg>
        <use xlink:href="/assets/img/sprite.svg#text"></use>
      </svg>
    </li>
    <li @click="tab('asset')">
      <svg>
        <use xlink:href="/assets/img/sprite.svg#image"></use>
      </svg>
    </li>
    <li @click="tab('link')">
      <svg>
        <use xlink:href="/assets/img/sprite.svg#url"></use>
      </svg>
    </li>
  </ul>
</template>

<script>
export default {

  methods: {
    tab(val) {
      this.$store.dispatch('texteditor/switchTab', val);
    }
  },

}
</script>

<style scoped>
@import './EditorTabs.css';
</style>