<template>
  <div>
    <div class="feed__empty" v-if="streamTab === 'live'">
      <h3>There are no Live messages.</h3>
      <p>
        Use the editor above to send a message and it will appear here!
      </p>
    </div>

    <div class="feed__empty" v-if="streamTab === 'ignored'">
      <h3>There are no Ignored messages.</h3>
      <p>
        In order to ignore a message click the Ignore button
        on the column next to the user’s message.
      </p>
    </div>

    <div class="feed__empty" v-if="streamTab === 'blocked'">
      <h3>There are no Blocked messages.</h3>
      <p>
        In order to block a user click the block button
        on the column next to the user’s message.
      </p>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

export default {

  computed: {
    ...mapGetters('stream', {
      streamTab: 'activeStreamTab',
    }),
  }

}
</script>

<style scoped>
.feed__empty {
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.feed__empty h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  line-height: 2rem;
}

.feed__empty p {
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.feed__empty p:not(:last-child) {
  margin-bottom: 10px;
}
</style>