<template>
  <div v-if="streamTab === 'live'" class="feed__actions">
    <button @click="emitFilterMessages('highlights')">
      <svg v-if="!streamFilters.highlights">
        <use xlink:href="/assets/img/sprite.svg#highlight"></use>
      </svg>
      <svg v-if="streamFilters.highlights">
        <use xlink:href="/assets/img/sprite.svg#highlight-filled"></use>
      </svg>
      <span>View only Highlights</span>
    </button>
  </div>
</template>

<script>
export default {
  props: {
    streamTab: String,
    streamFilters: Object
  },
  methods: {
    emitFilterMessages(term) {
      this.$emit('filterMessages', term);
    }
  }
};
</script>

<style scoped>
.feed__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: #e3e4e6;
}

.feed__actions button {
  margin-right: 10px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.feed__actions button:last-child {
  margin-right: 0;
}

.feed__actions svg {
  margin-right: 5px;
  width: 17px;
  height: 17px;
  fill: var(--dark-blue);
}

.feed__actions span {
  vertical-align: top;
  text-decoration: underline;
  font: bold 1.4rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--dark-blue);
}

@media only screen and (max-width: 600px) {
  .feed__stream {
    padding: 15px 10px;
  }

  .feed__actions {
    flex-direction: column;
    align-items: flex-start;
    padding: 15px 10px;
  }

  .feed__actions button:not(:last-child) {
    margin: 0 0 10px;
  }

}
</style>
