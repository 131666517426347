<template>
  <input class="link-input" type="text" placeholder="URL" v-model="text" @input="createLink">
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  data() {
    return {
      text: '',
    }
  },

  methods: {
    createLink: function () {
      this.$store.dispatch('texteditor/createLink', this.text);
    },
  },

  computed: {
    ...mapGetters('texteditor', {
      linkExist: 'linkExist',
    }),
  },

  watch: {
    linkExist() {
      if (this.linkExist) {
        this.text = '';
      }
    },
  },

}
</script>

<style scoped>
@import './LinkInput.css';
</style>