<template>
  <div class="auth">
    <section class="auth__aside">
      <a href="/">
        <figure class="auth__logo">
          <img src="/assets/img/livecaster-logo-moto.svg" alt="LiveCaster logo">
        </figure>
      </a>
      <div class="auth__copyrights">
        &copy;2016-{{ new Date().getFullYear() }} pixual.co
      </div>
    </section>

    <LoginComponent v-if="$route.name === 'login'" />
    <RegisterComponent v-if="$route.name === 'register'" />
    <ForgotPassComponent v-if="$route.name === 'forgotpass'" />

  </div>
</template>
      
<script>
import LoginComponent from '@/components/screens/auth/LoginComponent.vue'
import RegisterComponent from '@/components/screens/auth/RegisterComponent.vue'
import ForgotPassComponent from '@/components/screens/auth/ForgotPassComponent.vue'

export default {

  components: {
    LoginComponent,
    RegisterComponent,
    ForgotPassComponent
  },

  data() {
    return {
      email: '',
      password: '',
      errors: '',
      isLogin: true,
      validation: {
        email: null,
        password: null,
      },
      required: true
    };
  },

};
</script>
    
<style scoped>
@import "@/assets/css/pages/auth.css";
</style>