<template>
  <div v-if="inDom" class="notification" :class="['notification--' + type, { 'visible': isVisible }]">
    <div class="notification__title">
      <svg>
        <use :xlink:href="`/assets/img/sprite.svg#${type}`"></use>
      </svg>
      {{ title }}
    </div>
    <div class="notification__body">{{ body }}</div>
    <span class="notification__close" @click.prevent="closeNotification"></span>
  </div>
</template>
          
<script>
export default {
  props: {
    title: {
      type: String,
      default: '',
    },
    body: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      isVisible: false,
      inDom: true,
    };
  },

  mounted() {
    setTimeout(() => {
      this.isVisible = true;
    }, 300);
  },

  methods: {
    closeNotification() {
      this.isVisible = false;
      setTimeout(() => {
        this.inDom = false;
      }, 400);
    }
  }
};
</script>

        
<style scoped>
.notification {
  position: relative;
  display: flex;
  align-items: center;
  max-width: 425px;
  width: 100%;
  right: calc(-100% - 12px);
  padding: 10px 40px 10px 10px;
  margin-bottom: 15px;
  border-radius: 10px 0 10px 10px;
  background: #fff;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.3);
  transition: 0.2s;
}

.notification.visible {
  right: 0;
}

.notification__title {
  display: flex;
  align-items: center;
  align-self: flex-start;
  margin-right: 20px;
  font-size: 1.8rem;
  line-height: 2rem;
}

.notification__title svg {
  width: 36px;
  height: 36px;
  margin-right: 10px;
}

.notification--info .notification__title {
  color: var(--light-blue);
}

.notification--info svg {
  fill: var(--light-blue);
}

.notification--warning .notification__title {
  color: var(--orange);
}

.notification--warning svg {
  fill: var(--orange);
}

.notification--error .notification__title {
  color: var(--red);
}

.notification--error svg {
  fill: var(--red);
}

.notification--success .notification__title {
  color: var(--green);
}

.notification--success svg {
  fill: var(--green);
}

.notification__body {
  margin-top: 3px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  color: #000;
}

.notification__body a {
  color: var(--dark-blue);
  text-decoration: underline;
}

.notification__close {
  position: absolute;
  top: 25px;
  right: 10px;
  width: 10px;
  height: 10px;
  cursor: pointer;
}

.notification__close:before,
.notification__close:after {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  width: 11px;
  height: 2px;
  background: #000;
  border-radius: 2px;
}

.notification__close:before {
  transform-origin: bottom left;
  transform: rotate(45deg) translate(0px, -1px);
}

.notification__close:after {
  transform-origin: bottom right;
  transform: rotate(-45deg) translate(0px, -1px);
}

@media only screen and (max-width: 600px) {
  .notifications {
    padding: 0;
    right: 10px;
    left: 10px;
  }
}
</style>