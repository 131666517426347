<template>
  <div :data-active="active" @dragenter.prevent="setActive" @dragover.prevent="setActive" @dragleave.prevent="setInactive"
    @drop.prevent="onDrop">

    <div class="dropzone-previews" v-show="files.length">
      <FilePreview v-for="file of files" :key="file.id" :file="file" tag="div" @remove="handleFileRemove" />
    </div>

    <label for="file-input" class="dz-message-container">
      <div class="dz-message">
        <svg>
          <use xlink:href="/assets/img/sprite.svg#image"></use>
        </svg>
        <span>Choose a file or drag it here</span>
      </div>

      <input type="file" id="file-input" @change="onInputChange" />
    </label>

  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, defineEmits } from 'vue';
import useFileList from '@/compositions/file-list';
import { UploadableFile } from '@/compositions/file-list';
import FilePreview from '@/components/misc/dropzone/FilePreview.vue'

const { files, addFiles, removeFile } = useFileList()

const emit = defineEmits(['files-dropped', 'upload-success', 'upload-error', 'file-removed']);

let active = ref(false);
let inActiveTimeout = null;

async function onInputChange(e) {
  await processAndUploadFiles(e.target.files);
  addFiles(e.target.files);
  e.target.value = null; // reset the input
}

function setActive() {
  active.value = true;
  clearTimeout(inActiveTimeout);
}

function setInactive() {
  inActiveTimeout = setTimeout(() => {
    active.value = false;
  }, 50);
}

async function onDrop(e) {
  setInactive();
  await processAndUploadFiles(e.dataTransfer.files);
}

function handleFileRemove(file) {
  removeFile(file);
  emit('file-removed', file);
}

async function processAndUploadFiles(rawFiles) {
  const filesUploaded = [...rawFiles].filter(file => file.type.startsWith('image/'));
  if (filesUploaded.length === 0) {
    emit('upload-error', new Error('No image files detected'));
    return;
  }

  try {
    const formData = new FormData();
    filesUploaded.forEach(file => formData.append('files', file));

    const response = await fetch('https://us-central1-livecaster-dev.cloudfunctions.net/uploadAndOptimizeImage', {
      method: 'POST',
      body: formData,
    });

    if (!response.ok) {
      throw new Error('Upload failed');
    }

    const result = await response.json();

    const uploadableFiles = filesUploaded.map(function (file) {
      file.uploadedPath = result.objectName;
      return new UploadableFile(file)
    });
    emit('files-dropped', filesUploaded);

    emit('upload-success', uploadableFiles[0]);

  } catch (error) {
    console.error('Upload error:', error);
    emit('upload-error', error);
  }
}


function preventDefaults(e) {
  e.preventDefault();
}

const events = ['dragenter', 'dragover', 'dragleave', 'drop'];

onMounted(() => {
  events.forEach(eventName => {
    document.body.addEventListener(eventName, preventDefaults);
  });
});

onUnmounted(() => {
  events.forEach(eventName => {
    document.body.removeEventListener(eventName, preventDefaults);
  });
});
</script>

<style scoped>
#file-input {
  display: none;
}

.dropzone-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  position: absolute;
  top: 56px;
  left: 0;
  right: 0;
  margin: 0 10px;
  background: #fff;
}

.dropzone {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  min-height: 120px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  background: #f8f8f8;
  padding: 0 10px;
  cursor: pointer;
}


.dropzone .dz-message-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
}

.dropzone .dz-message {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.dropzone .dz-message svg {
  width: 30px;
  height: 30px;
  margin-bottom: 10px;
  fill: rgba(0, 0, 0, 0.2);
}

.dropzone .dz-message span {
  font-size: 1.4rem;
  line-height: 2rem;
  font-weight: 600;
  text-align: center;
  color: #393939;
}

.dropzone-previews {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
  -ms-flex: 0 1 auto;
  flex: 0 1 auto;
  padding: 15px 15px 0 0;
  max-width: 80%;
  cursor: -webkit-grab;
  cursor: grab;
  overflow: auto;
}

.dropzone-previews::-webkit-scrollbar {
  height: 3px;
  background: transparent;
}

.dropzone-previews::-webkit-scrollbar-thumb {
  background: var(--blue);
}

@media only screen and (max-width: 600px) {
  .dropzone {
    min-height: 80px;
  }

  .dropzone .dz-message svg {
    width: 20px;
    height: 20px;
  }

  .dropzone .dz-message span {
    font-size: 1.3rem;
    line-height: 1.4rem;
  }
}
</style>