<template>
  <header class="header">
    <nav class="nav">
      <a href="/" title="Livecaster">
        <figure class="logo">
          <img src="/assets/img/landing/livecaster-logo.svg" alt="Livecaster">
        </figure>
      </a>
      <div>
        <a href="/login" class="main-button main-button--white" title="Login">LOGIN</a>
        <a v-if="false" href="/invite" class="main-button main-button--blue" title="Request access">REQUEST ACCESS</a>
      </div>
    </nav>
  </header>

  <main class="main">
    <div class="intro">
      <h1>Live Blogging</h1>
      <span data-title="re-imagined">re-imagined</span>
    </div>

    <section class="section section-1" data-watermark="features">
      <div class="section__left">
        <h2 class="section__title">Intuitive Moderation</h2>
        <div class="section__content">
          <p>Take full control over your live blogging!</p>
          <p>
            Easy access to all available moderation settings allows you to focus
            on what matters: <strong>Real-time engagement with your audience</strong>.
          </p>
          <p>
            You will never feel overwhelmed again by the large number of incoming
            messages from your users. Livecaster's intuitive moderation makes it
            easier than ever to keep up with the flow, even when user engagement
            is high.
          </p>
        </div>
      </div>
      <div class="section__right">
        <figure class="section__image">
          <img src="/assets/img/landing/lc-moderation.png" alt="Livecaster intuitive moderation">
        </figure>
      </div>

    </section>

    <section class="section section-2">
      <div class="section__left">
        <h2 class="section__title">Works on any platform</h2>
        <div class="section__content">
          <p>Optimized for performance</p>
          <p>SEO Friendly</p>
        </div>

      </div>
      <div class="section__right">
        <div class="section-2__snippet">
          <ul>
            <li>Configurable style</li>
            <li>oEmbed support</li>
            <li>Rest API</li>
          </ul>
        </div>
      </div>

    </section>

    <section class="section section-3">
      <div class="section__left">
        <h2 class="section__title">Create, Edit & Archive</h2>
        <div class="section__content">
          <p>
            View and manage your streams with ease from your dashboard. Create,
            Edit & Schedule your live sessions without a hassle.
          </p>
        </div>
      </div>
      <div class="section__right">
        <figure class="section__image">
          <img src="/assets/img/landing/lc-card.png" alt="Livecaster stream card">
        </figure>
      </div>
    </section>

    <section class="section section-4">
      <div class="section__left">
        <h2 class="section__title">Get to know your audience</h2>
        <div class="section__content">
          <p>
            View detailed statistics on user engagement per stream. Filter out
            abusive messages from users by blocking them out of your sessions.
          </p>
        </div>

      </div>
      <div class="section__right">
        <figure class="section__image">
          <img src="/assets/img/landing/people.svg" alt="Users">
        </figure>
      </div>
    </section>

    <section class="section section-5">
      <h2 class="section__title">Use Cases</h2>
      <ul class="section-5__use-cases">
        <li>
          <span class="section-5__description">MODERATED</span>
          <span class="section-5__title">LIVE BLOGGING</span>
        </li>
        <li>
          <span class="section-5__description">REAL-TIME</span>
          <span class="section-5__title">COVERAGE OF EVENTS</span>
        </li>
        <li>
          <span class="section-5__description">INTERACTIVE</span>
          <span class="section-5__title">CONVERSATION WITH YOUR AUDIENCE</span>
        </li>
      </ul>
    </section>

    <section class="section section-6" data-watermark="cases">
      <div class="section__left">
        <h2 class="section__title">Made for publishers</h2>
        <div class="section__content">
          <p>
            Communicating with your audience in real-time is a perfect way to
            increase the engagement on your website. Publishers with high
            expectations trust Livecaster for their everyday live blogs and
            real-time coverage of important events.
          </p>
        </div>
        <a v-if="false" href="/invite" class="main-button" title="REQUEST INVITE">REQUEST INVITE</a>
      </div>
      <div class="section__right">
        <a href="https://www.sdna.gr" class="section-6__user" target="_blank" title="SDNA" rel="noopener">
          <figure>
            <img src="/assets/img/landing/sdna.svg" alt="SDNA">
          </figure>
        </a>
        <a href="https://www.betcosmos.com/" class="section-6__user" target="_blank" title="Betcosmos" rel="noopener">
          <figure>
            <img src="/assets/img/landing/betcosmos.svg" alt="Betcosmos">
          </figure>
        </a>
      </div>
    </section>

    <section v-if="false" class="section section-7">
      <h2 class="section__title">Choose or build your own plan</h2>
      <div class="section-7__plans">

        <a href="#" class="plan">
          <div class="plan__head plan__head--starter">
            <span class="plan__price">99&euro;</span>
            <span class="plan__month">per month</span>
            <span class="plan__year">Billed annually or 110&euro; billed monthly</span>
          </div>
          <div class="plan__body">
            <h3 class="plan__title">STARTER</h3>
            <span>Plan</span>
            <ul class="plan__benefits">
              <li>Up to 1000 concurrent users</li>
              <li>LIVE Analytics</li>
              <li>Monitoring</li>
            </ul>
          </div>
        </a>

        <a href="#" class="plan">
          <div class="plan__head plan__head--basic">
            <span class="plan__price">199&euro;</span>
            <span class="plan__month">per month</span>
            <span class="plan__year">Billed annually or 220&euro; billed monthly</span>
          </div>
          <div class="plan__body">
            <h3 class="plan__title">STARTER</h3>
            <span>Plan</span>
            <ul class="plan__benefits">
              <li>Up to 2000 concurrent users</li>
              <li>LIVE Analytics</li>
              <li>Monitoring</li>
            </ul>
          </div>
        </a>

        <a href="#" class="plan">
          <div class="plan__head plan__head--advanced">
            <span class="plan__price">399&euro;</span>
            <span class="plan__month">per month</span>
            <span class="plan__year">Billed annually or 430&euro; billed monthly</span>
          </div>
          <div class="plan__body">
            <h3 class="plan__title">STARTER</h3>
            <span>Plan</span>
            <ul class="plan__benefits">
              <li>Up to 4000 concurrent users</li>
              <li>LIVE Analytics</li>
              <li>Monitoring</li>
            </ul>
          </div>
        </a>

      </div>
    </section>
  </main>

  <footer class="footer">
    <a v-if="false" href="/invite" class="footer__invitation" title="Request access">REQUEST ACCESS</a>
    <div class="footer__pixual">
      <figure>
        <img src="/assets/img/livecaster-logo.svg" alt="Livecaster">
      </figure>
      <span>
        <a target="_blank" href="https://www.pixual.co" title="A product by Pixual" rel="noopener">a product by
          pixual.co</a>
        <span>&copy;2016-2023 pixual.co</span>
      </span>
    </div>
  </footer>
</template>
  
<script>
export default {
  name: 'LandingScreen',

  methods: {
    handleNav() {
      const header = document.querySelector('.header');
      if (window.pageYOffset > 50 && !header.classList.contains('shrink')) {
        header.classList.add('shrink');
      } else if (window.pageYOffset < 50 && header.classList.contains('shrink')) {
        header.classList.remove('shrink');
      }

      const footer = document.querySelector('.footer');
      if (footer.getBoundingClientRect().top < window.innerHeight && footer.getBoundingClientRect().top > 0) {
        header.classList.add('hide');
      } else if (header.classList.contains('hide')) {
        header.classList.remove('hide');
      }
    },

    handleSections(range) {
      const sections = document.querySelectorAll('.section');
      sections.forEach((elm) => {
        if (elm.getBoundingClientRect().top < window.innerHeight - range && !elm.classList.contains('visible')) {
          elm.classList.add('visible');
        }
      });
    },

    transitionDelay(obj, initialDelay = 0) {
      obj.forEach((elm) => {
        initialDelay += 0.2;
        elm.style.transitionDelay = initialDelay + 's';
      });
    },

    animateIntro() {
      setTimeout(() => {
        const introElement = document.querySelector('.intro');
        if (introElement) {
          introElement.classList.add('visible');
        }
      }, 100);
    },

    setupScrollEvents() {
      window.addEventListener('scroll', () => {
        this.handleNav();
        window.innerWidth > 768 ? this.handleSections(150) : this.handleSections(100);
      });
    },

    handleInitialAnimations() {
      this.setupScrollEvents();
      this.animateIntro();
    },
  },
  mounted() {
    this.handleInitialAnimations();
  },

}
</script>

<style scoped>
@import '@/assets/css/pages/landing.css';
</style>