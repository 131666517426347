<template>
  <section class="auth__content-wrap" v-if="false">
    <div class="auth__content">
      <h1>Register</h1>
      <form class="auth__form" @submit.prevent="signup()">

        <FormEmail :val="email" :validation="validation.email" @update_val="update_email" />

        <FormPassword name="password" :val="password" :validation="validation.password" @update_val="update_password"
          :required="required" />

        <span v-if="errors.length" class="error-message">{{ errors }}</span>

        <input class="form__submit form__submit--gradient" type="submit" value="REGISTER" />
      </form>

      <div class="auth__question">
        Don't have an account?
        <a href="#" @click.prevent="this.$router.push('/login')">Log in!</a>
      </div>
    </div>
  </section>
</template>
          
<script>
import { auth } from '@/firebase';
import { useRouter } from 'vue-router';

import FormEmail from '@/components/forms/FormEmail.vue'
import FormPassword from '@/components/forms/FormPassword.vue'

export default {
  components: {
    FormEmail,
    FormPassword
  },

  data() {
    return {
      email: '',
      password: '',
      errors: '',
      validation: {
        email: null,
        password: null,
      },
      required: true
    };
  },

  setup() {
    const router = useRouter();
    return { router };
  },

  methods: {

    async signup() {
      try {
        const userCredential = await auth.createUserWithEmailAndPassword(this.email, this.password);
        console.log('Signed up:', userCredential.user);
      } catch (error) {
        console.error('Signup failed:', error);
        this.errors = "The email address is already in use by another account.";
      }
    },

    validateForm: function () {
      let email = this.user.email.trim();
      let password = this.user.password.trim();

      this.validation.email = (email.length < 4) ? false : true;
      this.validation.password = (password.length < 4) ? false : true;

      return (this.validation.email && this.validation.password);
    },

    update_email: function (val) {
      this.email = val;
    },

    update_password: function (val) {
      this.password = val;
    },

  },
};
</script>
  
<style scoped>
@import '@/assets/css/components/form-elements.css';

h1 {
  display: block;
  margin-bottom: 20px;
  font: 300 3.2rem/3.5rem 'Ubuntu';
  color: var(--gray);
}

h1+p {
  margin-bottom: 20px;
  font: 400 1.4rem/2rem 'Ubuntu';
  color: var(--gray);
}
</style>