import { createRouter, createWebHistory } from 'vue-router';
import { db, auth } from './firebase';
import LandingScreen from './components/screens/LandingScreen.vue';
import AuthScreen from './components/screens/auth/AuthScreen.vue';
import DashboardHome from './components/screens/dashboard/DashboardHome.vue';
import CreateEvent from './components/screens/dashboard/CreateEvent.vue';
import EmbedScreen from './components/screens/front/EmbedScreen.vue';
import EventDetail from './components/screens/dashboard/EventDetail.vue';
import UserProfile from './components/screens/dashboard/UserProfile.vue';
import NotFound from './components/screens/NotFound.vue';

const domain = window.location.hostname;

let routes;
if (domain === process.env.VUE_APP_EMBED_DOMAIN) {
    // Define routes specific for embed.
    routes = [
        {
            path: '/:eventId',
            name: 'embed',
            component: EmbedScreen,
            meta: { requiresAuth: false },
            props: true,
            beforeEnter: async (to, from, next) => {
                const isValid = await isValidEventId(to.params.eventId);
                if (isValid) {
                    next();
                } else {
                    next({ name: 'notFound' });
                }
            }
        },
        { path: '/not-found', name: 'notFound', component: NotFound, meta: { requiresAuth: false } }
    ];
} else {
    routes = [
        { path: '/', name: 'home', component: LandingScreen, meta: { requiresAuth: false } },
        { path: '/login', name: 'login', component: AuthScreen, meta: { requiresAuth: false } },
        { path: '/register', name: 'register', component: AuthScreen, meta: { requiresAuth: false } },
        { path: '/forgot-password', name: 'forgotpass', component: AuthScreen, meta: { requiresAuth: false } },
        { path: '/embed/:eventId', name: 'embed', component: EmbedScreen, meta: { requiresAuth: false }, props: true },
        { path: '/not-found', name: 'notFound', component: NotFound, meta: { requiresAuth: false } },
        { path: '/:pathMatch(.*)*', redirect: '/not-found', meta: { requiresAuth: false } },

        // Back-end
        {
            path: '/dashboard',
            meta: { requiresAuth: true },
            children: [
                { path: '', component: DashboardHome, meta: { requiresAuth: true } },
                { path: '/create', component: CreateEvent, meta: { requiresAuth: true } },
                { path: '/profile', component: UserProfile, meta: { requiresAuth: true } },
                {
                    path: '/event/:eventId',
                    name: 'event',
                    component: EventDetail,
                    meta: { requiresAuth: true },
                    props: true,
                    beforeEnter: async (to, from, next) => {
                        const isValid = await isMyValidEventId(to.params.eventId);
                        if (isValid) {
                            next();
                        } else {
                            next('/dashboard');
                        }
                    }
                },
            ],
        },

    ];
}

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    const currentUser = auth.currentUser;

    if (requiresAuth && (!currentUser || currentUser.isAnonymous)) {
        next({ path: '/login', query: { redirect: to.fullPath } });
    } else if (to.path === '/login' && currentUser && !currentUser.isAnonymous) {
        const redirect = to.query.redirect || '/dashboard';
        next(redirect);
    } else {
        next();
    }
});

async function isValidEventId(eventId) {
    const eventRef = db.collection('events').doc(eventId);
    const doc = await eventRef.get();
    return doc.exists;
}

async function isMyValidEventId(eventId) {
    const eventRef = db.collection('events').doc(eventId);
    const doc = await eventRef.get();

    if (!doc.exists) {
        return false;
    }

    const eventData = doc.data();
    return eventData.userId === auth.currentUser.uid;
}

export default router;
