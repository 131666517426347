<template>
  <div class="form-group" :class="isValid">
    <svg class="icon">
      <use xlink:href="/assets/img/sprite.svg#email"></use>
    </svg>
    <input @keypress="clearField" v-model="email" type="email" name="email" placeholder="EMAIL" required />
    <span>email</span>
  </div>
</template>

<script>
export default {
  props: ['val', 'validation'],
  data() {
    return {
      email: this.val,
    }
  },
  methods: {
    clearField: function () {
      //  clear field
    },
  },
  computed: {
    isValid: function () {
      if (this.validation != null) {
        return (this.validation) ? 'valid' : 'invalid';
      }
      return '';
    }
  },
  watch: {
    email() {
      this.$emit('update_val', this.email);
    },
  }
}
</script>