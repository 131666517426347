<template>
  <figure class="bubble__assets" @click="showLightbox">
    <img :src="imageUrl">
    <span v-if="images.length > 1">
      View {{ images.length }} images
    </span>
  </figure>
</template>

<script>
import { openModal } from "jenesius-vue-modal";
import ModalAssetsLightbox from '@/components/misc/modals/AssetsLightbox'

export default {

  props: ['images'],

  methods: {
    showLightbox() {
      openModal(ModalAssetsLightbox, { images: this.images });
      //   modalHandler('assets_lightbox', { images: this.images });
    },
  },

  computed: {
    imageUrl() {
      let path = this.images[0];
      return path.startsWith('http') ? path : 'https://st1.livecaster.io/lc1/' + path;
    }
  }

}
</script>

<style scoped>
/* Assets */
.bubble__assets {
  position: relative;
  margin: 20px;
  cursor: pointer;
}

.bubble__assets img {
  object-fit: cover;
  width: 100%;
}

.bubble__assets span {
  position: absolute;
  padding: 7px 10px;
  bottom: 0;
  right: 0;
  font-size: 1.4rem;
  line-height: 1.7rem;
  font-weight: 500;
  color: #fff;
  background: var(--blue);
}

.bubble__text+.bubble__assets {
  margin-top: 0;
}
</style>