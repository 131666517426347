<template>
  <div class="modal">
    <div class="pop-up__form">
      <h2 class="pop-up__title">Are you sure?</h2>
      <div class="pop-up__message">
        This action will end your live event.
      </div>
      <span @click="close" class="pop-up__btn pop-up__btn--dismiss">CANCEL</span>
      <span @click.prevent="archive" class="pop-up__btn pop-up__btn--approve">YES</span>
    </div>

  </div>
</template>

<script>
import { closeModal } from 'jenesius-vue-modal'

export default {

  data() {
    return {
      prevExist: false,
      nextExist: true,
    }
  },

  components: {
    // SwiperBox,
  },

  methods: {
    close() {
      closeModal();
    },
    archive() {
      this.$store.dispatch('stream/archive', this.$route.params.eventId);
      closeModal();
    }
  },

}
</script>

<style scoped>
.modal {
  width: 100%;
  max-width: 500px;
}

.pop-up__form {
  display: block;
  position: relative;
  max-width: 500px;
  padding: 30px 20px 40px;
  text-align: center;
  color: var(--gray);
  background: #fff;
}

.pop-up__title {
  margin-bottom: 20px;
  font-size: 3.2rem;
  line-height: 5.5rem;
  font-weight: 200;
}

.pop-up__message {
  margin-bottom: 30px;
  font-size: 1.4rem;
  line-height: 2.4rem;
}

.pop-up__btn {
  max-width: 110px;
  width: 100%;
  padding: 12px 25px;
  margin: 0 10px;
  font-size: 1.4rem;
  line-height: 1.6rem;
  font-weight: bold;
  border-radius: 40px;
  color: #fff;
  outline: none;
}

.pop-up__btn--dismiss {
  background: #848484;
  cursor: pointer;
}

.pop-up__btn--approve {
  border: none;
  background: linear-gradient(to bottom, var(--orange), var(--fuchsia));
  cursor: pointer;
}

.pop-up__avatar {
  position: relative;
  margin: 0 auto 30px;
  width: 100px;
  height: 100px;
  border-radius: 50%;
  background: var(--petrol);
}

.pop-up__avatar:after {
  content: attr(data-name);
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 3.4rem;
  line-height: 4rem;
  font-weight: bold;
  color: #fff;
}

.pop-up__avatar img {
  border-radius: 50%;
}

.pop-up__avatar svg {
  position: absolute;
  bottom: 0;
  right: 0;
  width: 30px;
  height: 30px;
  padding: 5px;
  border-radius: 50%;
  background: #000;
  fill: #fff;
  box-shadow: 0 4px 4px rgba(0, 0, 0, 0.29);
  cursor: pointer;
}
</style>