import { db, auth, functions } from '@/firebase'
import { serverTimestamp } from 'firebase/firestore'

const state = function () {
  return {
    canSubmit: false,
    tab: {
      active: 'text',
      variation: ''
    },
    editor: {
      reset: false,
      text: '',
      autoSubmit: false,
    },
    assets: {
      amount: 0,
      collection: [],
    },
    link: {
      exist: false,
      data: {},
    },
  }
}

const getters = {
  activeTab(state) {
    return state.tab.active;
  },

  tabVariation(state) {
    return state.tab.variation;
  },

  canSubmit(state) {
    return state.canSubmit;
  },

  autoSubmit(state) {
    return state.editor.autoSubmit;
  },

  editorText(state) {
    return state.editor.text;
  },

  link(state) {
    return state.link.data;
  },

  linkExist(state) {
    return state.link.exist;
  },

  assetsAmount(state) {
    return state.assets.amount;
  },

  editorReset(state) {
    return state.editor.reset;
  },
}

const actions = {
  switchTab({ commit, state, dispatch }, val) {
    if (val !== state.tab.active) {
      switch (val) {
        case 'text':
          commit('ACTIVE_TAB', { active: 'text', variation: '' });
          commit('RESET_LINK');
          commit('RESET_ASSETS');
          break;
        case 'asset':
          commit('ACTIVE_TAB', { active: 'asset', variation: '' });
          commit('RESET_LINK');
          break;
        case 'link':
          commit('ACTIVE_TAB', { active: 'link', variation: '' });
          commit('RESET_ASSETS');
          break;
      }
    }
    dispatch('handleSubmit');
  },

  handleSubmit({ commit, state }) {
    if (state.editor.text.length) {
      commit('SUBMIT_PERMISSION', true);
      return false;
    }
    else {
      if (state.tab.active === 'link' && state.link.exist === true) {
        commit('SUBMIT_PERMISSION', true);
      }
      else if (state.tab.active === 'asset' && state.assets.collection.length) {
        commit('SUBMIT_PERMISSION', true);
      }
      else {
        commit('SUBMIT_PERMISSION', false);
      }
    }
  },

  toggleAutosend({ commit }, val = null) {
    commit('TOGGLE_AUTOSUBMIT', val);
  },

  editorText({ commit, dispatch }, val) {
    commit('SET_TEXT', val);
    dispatch('handleSubmit');
  },

  assetsAmount({ commit }, amount) {
    if (amount) {
      commit('ACTIVE_TAB', { active: 'asset', variation: 'asset--preview' });
    }
    else {
      commit('ACTIVE_TAB', { active: 'asset', variation: '' });
    }
    commit('ASSETS_AMOUNT', amount);
  },

  uploadedAssets({ commit, dispatch }, url) {
    commit('ASSETS_COLLECTION', url);
    dispatch('handleSubmit');
  },

  removeAsset({ commit, dispatch }, url) {
    commit('REMOVE_ASSET_FROM_COLLECTION', url);
    dispatch('handleSubmit');
  },

  createLink({ commit, dispatch }, val) {

    const getXEmbedData = functions.httpsCallable('getXEmbedData');

    // Call the Cloud Function
    getXEmbedData({ url: val })
      .then((result) => {
        if (typeof result.data.type !== 'undefined') {
          commit('CREATE_LINK', result.data);
          commit('ACTIVE_TAB', { active: 'link', variation: 'link--preview' });
          dispatch('handleSubmit');
        }
      })
      .catch((error) => {
        // Handle any errors
        console.error('Error:', error);
      });

  },

  resetLink({ commit, dispatch }) {
    commit('RESET_LINK');
    commit('ACTIVE_TAB', { active: 'link', variation: '' });
    dispatch('handleSubmit');
  },

  async sendContent({ commit, dispatch, state }, eventId) {

    let data = { type: 'text', oembed: null, images: [] };

    switch (state.tab.active) {
      case 'link':
        if (state.link.exist) {
          data.type = 'link';
          data.url = state.link.data.url;
          data.oembed = state.link.data;
        }
        break;
      case 'asset':
        if (state.assets.collection.length) {
          data.images = state.assets.collection;
          data.type = 'asset';
        }
        break;
    }

    if (data.type === 'text' && (!state.editor.text || !state.editor.text.trim())) {
      console.log("Cannot send an empty text message.");
      return; // Exit the function if the text message is empty
    }

    try {
      const eventRef = db.collection("events").doc(eventId);
      const messagesCollection = eventRef.collection("messages");
      const timestamp = serverTimestamp();

      await messagesCollection.add({
        type: data.type,
        oembed: data.oembed,
        message: state.editor.text,
        status: 1,
        highlight: false,
        user: auth.currentUser.displayName,
        guest: false,
        created: timestamp,
        updated: timestamp,
        images: data.images,
      });

      commit('ACTIVE_TAB', { active: 'text', variation: '' });
      commit('RESET_TEXT');
      commit('RESET_LINK');
      commit('RESET_ASSETS');
      dispatch('handleSubmit');

    } catch (error) {
      console.error("Error creating message:", error);
    }

  },
}

const mutations = {
  ACTIVE_TAB(state, tab) {
    state.tab.active = tab.active;
    state.tab.variation = tab.variation;
  },

  SET_TEXT(state, val) {
    state.editor.text = val;
    state.editor.reset = false;
  },

  CREATE_LINK(state, data) {
    state.link.data = data;
    state.link.exist = true;
  },

  RESET_LINK(state) {
    state.link.data = {};
    state.link.exist = false;
  },

  SUBMIT_PERMISSION(state, permission) {
    state.canSubmit = permission;
  },

  TOGGLE_AUTOSUBMIT(state, val = null) {
    if (val == null) {
      state.editor.autoSubmit = !state.editor.autoSubmit;
    }
    else {
      state.editor.autoSubmit = val
    }
  },

  ASSETS_AMOUNT(state, amount) {
    state.assets.amount = amount;
  },

  ASSETS_COLLECTION(state, url) {
    state.assets.collection.push(url);
  },

  REMOVE_ASSET_FROM_COLLECTION(state, url) {
    const index = state.assets.collection.indexOf(url);
    if (index > -1) {
      state.assets.collection.splice(index, 1);
    }
  },

  RESET_ASSETS(state) {
    state.assets.amount = 0;
    state.assets.collection = [];
  },

  RESET_TEXT(state) {
    state.editor.reset = true;
  },
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
}