<template>
  <div v-if="tab === 'live'" class="bubble__actions">
    <button @click="highlightMessage" class="bubble__action bubble__action--highlight">
      <svg v-if="!highlight">
        <use xlink:href="/assets/img/sprite.svg#highlight"></use>
      </svg>
      <svg v-else>
        <use xlink:href="/assets/img/sprite.svg#highlight-filled"></use>
      </svg>
      <span>Add to Highlights</span>
    </button>
    <button @click="deleteMessage" v-if="!deleteMode" class="bubble__action bubble__action--delete">
      <svg>
        <use xlink:href="/assets/img/sprite.svg#delete"></use>
      </svg>
      <span>Delete</span>
    </button>
    <button v-if="deleteMode" class="bubble__action bubble__action--delete">
      <svg>
        <use xlink:href="/assets/img/sprite.svg#delete"></use>
      </svg>
      <span>Are you sure?</span>
    </button>
    <button @click="confirmDeleteMessage" v-if="deleteMode" class="bubble__action bubble__action--delete">
      <span>Yes</span>
    </button>
    <button @click="cancelDeleteMessage" v-if="deleteMode" class="bubble__action bubble__action--delete">
      <span>No</span>
    </button>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {

  props: ['eventId', 'id', 'highlight'],

  data() {
    return {
      deleteMode: false,
    }
  },

  methods: {
    deleteMessage() {
      this.deleteMode = true;
    },

    confirmDeleteMessage() {
      let data = { eventId: this.eventId, id: this.id, highlight: this.highlight }
      this.$store.dispatch('stream/deleteMessage', data);
    },

    cancelDeleteMessage() {
      this.deleteMode = false;
    },

    highlightMessage() {
      let data = { eventId: this.eventId, id: this.id, highlight: this.highlight }
      this.$store.dispatch('stream/highlightMessage', data);
    }
  },

  computed: {
    ...mapGetters('stream', {
      tab: 'activeStreamTab',
    }),
  },

}
</script>

<style scoped>
@import './BubbleActions.css';
</style>