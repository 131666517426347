<template>
  <div>
    <form @submit.prevent="submit" ref="create_event" class="event" method="post" action="/create">
      <input class="event__title" v-model="title" name="title" type="text" placeholder="Event title" required />

      <div v-if="false">

        <!-- <ul class="event__tabs">
          <li @click="setTab('liveblog')" :class="{ 'active': tab === 'liveblog' }">Live blog</li>
          <li @click="setTab('sport')" :class="{ 'active': tab === 'sport' }">Sports event</li>
        </ul>

        <div v-if="tab === 'liveblog'" class="event__description">
          <p>Live blog is meant to deliver real-time updates on ongoing events or breaking news.</p>
          <p>Once created, you can select whether you want to allow users join a moderated conversation on not.</p>
        </div>

        <div v-if="tab === 'sport'" class="sports">
          <div class="sports__description">
            <p>Deliver real-time coverage of sports events for your audience.</p>
            <p>Select the sport, the teams and you are good to go!</p>
            <p>Once created, you can select whether you want to allow users join a moderated conversation on not.</p>
          </div>
          <ul class="sports__list">
            <li @click="setSportTab('football')" :class="{ 'active': sport.tab === 'football' }">Football</li>
            <li @click="setSportTab('basketball')" :class="{ 'active': sport.tab === 'basketball' }">Basketball</li>
          </ul>

          <div class="sports__select-wrap">
            <multiselect class="sports__select" name="home" v-model="sport.home" :options="teams" :taggable="true"
              group-values="teams" group-label="country" placeholder="SELECT HOME TEAM" tag-placeholder="Create team"
              track-by="name" label="name" @tag="createHomeTeam">
            </multiselect>
            <multiselect class="sports__select" name="away" v-model="sport.away" :options="teams" :taggable="true"
              group-values="teams" group-label="country" placeholder="SELECT AWAY TEAM" tag-placeholder="Create team"
              track-by="name" label="name" @tag="createAwayTeam">
            </multiselect>
          </div>
        </div> -->

      </div>

      <button class="form-submit" type="submit">CREATE EVENT</button>
    </form>
  </div>
</template>
          
<script>
import { serverTimestamp } from 'firebase/firestore';
import { db, auth } from '@/firebase';

export default {

  components: {
  },

  data() {
    return {
      title: '',
      userComments: true,
      tab: 'liveblog',
      sport: {
        tab: 'football',
        home: '',
        away: '',
      },
      teams: [
        {
          country: 'My Teams',
          teams: [],
        },
        {
          country: 'Greece',
          teams: [
            { name: 'Team 1', value: 0 },
            { name: 'Team 2', value: 0 },
            { name: 'Team 3', value: 0 },
            { name: 'Team 4', value: 0 },
          ]
        },
        {
          country: 'Greece',
          teams: [
            { name: 'Team 1', value: 0 },
            { name: 'Team 2', value: 0 },
            { name: 'Team 3', value: 0 },
            { name: 'Team 4', value: 0 },
          ]
        },
      ],
    }
  },

  methods: {

    async createEvent(data) {
      const user = auth.currentUser;
      if (user) {
        const timestamp = serverTimestamp();
        await db.collection('events').add({
          userId: user.uid,
          title: data.title,
          type: data.type,
          status: 1,
          created: timestamp,
        });
        this.$router.push('/dashboard');
      } else {
        console.error("User not authenticated");
      }
    },

    setTab(val) {
      this.tab = val;
    },

    setSportTab(val) {
      this.sport.tab = val;
    },

    submit() {
      if (this.tab === 'liveblog') {
        let data = {
          type: this.tab,
          title: this.title,
        };
        this.createEvent(data);
      }
      else {
        let data = {
          type: this.tab,
          title: this.title,
          sport: this.sport.tab,
          homeTeam: this.sport.home,
          awayTeam: this.sport.away,
        };
        console.log('create sport event', data);
      }
    },

    createOption(value) {
      console.log('Element created - ', value);
      let obj = { label: value.label, code: this.teams.length };
      this.teams.push(obj);
    },

    createHomeTeam(newTeam) {
      const team = { name: newTeam, value: 'custom' };
      this.teams[0].teams.push(team);
      this.sport.home = team;
    },

    createAwayTeam(newTeam) {
      const team = { name: newTeam, value: 'custom' };
      this.teams[0].teams.push(team);
      this.sport.away = team;
    },

  },

};
</script>
        
<style scoped>
h1 {
  margin-bottom: 20px;
  font-size: 3.2rem;
  line-height: 4rem;
  font-weight: 300;
  text-align: center;
  color: #fff;
}

.sessions-description {
  margin-top: 25px;
  text-align: center;
  color: #fff;
}

.event {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  max-width: 620px;
  width: 100%;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 30px auto 60px;
  padding: 20px 20px 0;
  background: #fff;
  color: var(--dark-gray);
}

.event p {
  margin: 0 0 15px;
}

.event p:last-child {
  margin: 0;
}

.event__title {
  margin: 0 0 20px;
  padding: 15px 10px;
  border: 1px solid #a5a5a5;
  font-size: 1.2rem;
  line-height: 1.8rem;
  font-weight: 500;
  outline: none;
}

.event__title::-webkit-input-placeholder {
  text-transform: uppercase;
  font: 500 1.2rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--gray);
}

.event__title::-moz-placeholder {
  text-transform: uppercase;
  font: 500 1.2rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--gray);
}

.event__title:-ms-input-placeholder {
  text-transform: uppercase;
  font: 500 1.2rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--gray);
}

.event__title::-ms-input-placeholder {
  text-transform: uppercase;
  font: 500 1.2rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--gray);
}

.event__title::placeholder {
  text-transform: uppercase;
  font: 500 1.2rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--gray);
}

.event__tabs {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  list-style-type: none;
}

.event__tabs li {
  position: relative;
  padding: 15px;
  -webkit-box-flex: 1;
  -ms-flex: 1;
  flex: 1;
  font-size: 1.6rem;
  line-height: 2rem;
  font-weight: bold;
  text-align: center;
  color: var(--gray);
  cursor: pointer;
}

.event__tabs li.active,
.event__tabs li:hover {
  background: -webkit-gradient(linear, left top, left bottom, from(rgba(11, 52, 217, 0.2)), to(rgba(11, 52, 217, 0.06)));
  background: -o-linear-gradient(top, rgba(11, 52, 217, 0.2), rgba(11, 52, 217, 0.06));
  background: linear-gradient(to bottom, rgba(11, 52, 217, 0.2), rgba(11, 52, 217, 0.06));
  color: var(--blue);
}

.event__tabs li.active:before,
.event__tabs li:hover:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 2px;
  background: var(--blue);
}

.event__description {
  padding: 15px 20px;
  background: rgba(11, 52, 217, 0.06);
  text-align: center;
}

.sports {
  padding: 15px 20px;
  background: rgba(11, 52, 217, 0.06);
}

.sports__description {
  padding: 0 20px 15px;
  text-align: center;
}

.sports__list {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  margin-bottom: 20px;
  text-align: center;
  list-style-type: none;
}

.sports__list li {
  position: relative;
  margin: 0 15px;
  font-size: 1.6rem;
  line-height: 1.7rem;
  font-weight: bold;
  cursor: pointer;
  color: var(--gray);
}

.sports__list li.active {
  color: var(--blue);
}

.sports__list li.active:after {
  content: '';
  position: absolute;
  bottom: -5px;
  left: 0;
  right: 0;
  height: 4px;
  background: -webkit-gradient(linear, left top, right top, from(var(--light-blue)), to(var(--blue)));
  background: -o-linear-gradient(left, var(--light-blue), var(--blue));
  background: linear-gradient(to right, var(--light-blue), var(--blue));
}

.sports__select-wrap {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  margin: 0 -5px;
}

.sports__select {
  margin: 0 5px;
  max-width: calc(50% - 10px);
  -webkit-box-flex: 1;
  -ms-flex: 1 0 100%;
  flex: 1 0 100%;
}

.sports__select .vs__dropdown-toggle {
  background: #fff;
  color: var(--gray);
}

.form-submit {
  display: block;
  margin: 20px -20px 0;
  padding: 17px 20px;
  text-align: center;
  font: bold 1.4rem/1.6rem 'Ubuntu', sans-serif;
  background: -webkit-gradient(linear, left top, right top, from(var(--light-blue)), to(var(--purple)));
  background: -o-linear-gradient(left, var(--light-blue), var(--purple));
  background: linear-gradient(to right, var(--light-blue), var(--purple));
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

@media only screen and (max-width: 600px) {

  .event__tabs li,
  .sports__list li {
    font-size: 1.4rem;
    line-height: 1.6rem;
  }

  .sports__list li.active:after {
    height: 3px;
  }

  .sports__select-wrap {
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    margin: 0;
  }

  .sports__select {
    margin: 0 0 10px;
    max-width: none;
    -webkit-box-flex: 1;
    -ms-flex: 1 0 100%;
    flex: 1 0 100%;
  }

  .sports__select:last-child {
    margin: 0;
  }
}
</style>