<template>
  <ckeditor id="editor" :editor="editor" :config="configuration" @ready="ready"></ckeditor>
</template>

<script>
import _ from 'lodash';
import { mapGetters } from 'vuex';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import CKEditor from '@ckeditor/ckeditor5-vue';

export default {

  data() {
    return {
      editorText: '',
      editor: ClassicEditor,
      configuration: {
        placeholder: 'Enter your Message',
        removePlugins: ['MediaEmbed'],
        toolbar: ['bold', 'italic', 'bulletedList', 'numberedList', 'link', 'blockquote', '|', 'undo', 'redo'],
      },
      debouncedDispatch: null,
      externalUpdate: false,
    }
  },

  created() {
    this.debouncedDispatch = _.debounce(this.dispatchEditorText, 100);
  },

  methods: {
    dispatchEditorText() {
      this.$store.dispatch('texteditor/editorText', this.editorText);
    },

    ready: function (editor) {
      let that = this;
      this.editor = editor;

      const eventId = this.$route.params.eventId;

      editor.editing.view.document.on('enter', function (evt, data) {
        if (that.enterMode) {
          that.$store.dispatch('texteditor/sendContent', eventId);
          data.preventDefault();
          evt.stop();
        }
      });

      editor.model.document.on('change:data', () => {
        const currentData = editor.getData();
        this.editorText = currentData;
      });
    },

    updateEditorContent(newContent) {
      if (this.editor.getData() !== newContent) {
        this.externalUpdate = true;
        this.editor.setData(newContent);
        this.externalUpdate = false;
      }
    },

    getScreenWdth() {
      console.log('getScreenWdth', this.editor);
    }
  },

  computed: {
    ...mapGetters('texteditor', {
      enterMode: 'autoSubmit',
      editorReset: 'editorReset',
    }),
  },

  watch: {
    editorText(newValue) {
      this.debouncedDispatch();
      if (this.externalUpdate) {
        this.updateEditorContent(newValue);
      }
    },

    editorReset(val) {
      if (val) {
        this.updateEditorContent('');
      }
    },
  },

  components: {
    ckeditor: CKEditor.component,
  },

}
</script>

<style >
.ck.ck-editor__top.ck-reset_all {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 46px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.ck.ck-toolbar {
  background: transparent;
}

.ck.ck-button,
a.ck.ck-button {
  cursor: pointer;
  background: transparent;
}

.ck.ck-button .ck-tooltip,
a.ck.ck-button .ck-tooltip {
  display: none;
}

.ck.ck-button.ck-disabled.ck-off {
  pointer-events: none;
}

.ck.ck-toolbar .ck.ck-button.ck-on,
a.ck.ck-button.ck-on,
.ck.ck-toolbar .ck.ck-button:not(.ck-disabled):active,
.ck.ck-toolbar a.ck.ck-button:not(.ck-disabled):active,
.ck.ck-toolbar .ck.ck-button:active,
.ck.ck-toolbar .ck.ck-button:focus,
.ck.ck-toolbar a.ck.ck-button:active,
.ck.ck-toolbar a.ck.ck-button:focus,
.ck.ck-toolbar .ck.ck-button:not(.ck-disabled):hover,
.ck.ck-toolbar a.ck.ck-button:not(.ck-disabled):hover {
  background: rgba(11, 52, 217, 0.2);
  border: none;
}

.ck.ck-icon,
.ck.ck-icon * {
  padding: 2px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
  margin-right: 110px;
  height: 100px;
  border: none;
}

.ck.ck-editor__editable_inline {
  padding: 0 10px;
}

.ck.ck-editor__editable_inline ul,
.ck.ck-editor__editable_inline ol {
  margin-left: 10px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable::-webkit-scrollbar {
  width: 4px;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable::-webkit-scrollbar-thumb {
  background: rgba(0, 0, 0, 0.3);
}

.ck-rounded-corners .ck.ck-editor__top .ck-sticky-panel .ck-toolbar,
.ck.ck-toolbar>.ck-toolbar__items>*,
.ck.ck-toolbar>.ck.ck-toolbar__grouped-dropdown {
  border: none;
}

.ck .ck-placeholder:before {
  font: 500 1.3rem/1.7rem 'Ubuntu', sans-serif;
  color: #646464;
}

.ck.ck-editor__editable:not(.ck-editor__nested-editable).ck-focused {
  -webkit-box-shadow: none;
  box-shadow: none;
}

.ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
  margin-top: 5px;
  margin-bottom: 5px;
}

.editor.asset .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
  margin-top: 140px;
  height: 50px;
}

.editor.asset--preview .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
  margin-top: 5px;
  height: 100px;
}

.editor.link .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
  margin-top: 70px;
}

.editor.link--preview .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
    margin-right: 0;
  }

  .editor.asset .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
    margin-top: 100px;
  }

  .editor.asset--preview .ck-rounded-corners .ck.ck-editor__main>.ck-editor__editable {
    margin-top: 5px
  }
}
</style>