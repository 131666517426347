<template>
  <section class="feed__main">
    <div class="feed__actions">
      <button @click="filterMessages('highlights')">
        <svg v-if="!filters.highlights">
          <use xlink:href="/assets/img/sprite.svg#highlight"></use>
        </svg>
        <svg v-if="filters.highlights">
          <use xlink:href="/assets/img/sprite.svg#highlight-filled"></use>
        </svg>
        <span>View only Highlights</span>
      </button>
      <button @click="filterMessages('host')">
        <svg v-if="!filters.host">
          <use xlink:href="/assets/img/sprite.svg#hide"></use>
        </svg>
        <svg v-if="filters.host">
          <use xlink:href="/assets/img/sprite.svg#view"></use>
        </svg>
        <span v-if="!filters.host">Hide all users comments</span>
        <span v-if="filters.host">Show all users comments</span>
      </button>
    </div>
    <div class="feed__stream">

      <div class="feed__empty" v-if="messages && !messages.length">
        <h3>There are no Live messages yet!</h3>
      </div>

      <!-- <Match v-for="(msg, index) in testMatchbubbles" :key="index" :msg="msg" /> -->

      <div v-for="msg in messages" :key="msg.id">
        <HostBubble :msg="msg" v-if="!msg.guest" />
        <GuestBubble :msg="msg" v-if="msg.guest" />
      </div>
      <div ref="trigger"></div>

      <!-- <LoadMore /> -->

    </div>
  </section>
</template>

<script>
import { mapGetters } from 'vuex';
import HostBubble from '@/components/misc/bubbles/main/HostBubble'
import GuestBubble from '@/components/misc/bubbles/main/GuestBubble'
// import Match from '../../../components/bubbles/match/Match'
// import LoadMore from '../../../components/helpers/LoadMoreMessages'

export default {

  data() {
    return {
      isFetching: false,
      observer: null,
      allMessagesLoaded: false,
    }
  },

  async mounted() {
    const options = {
      root: null,
      rootMargin: '0px 0px 10px',
      threshold: '0'
    };

    this.observer = new IntersectionObserver(this.handleIntersection, options);
    this.observer.observe(this.$refs.trigger);
  },

  methods: {
    filterMessages(term) {
      this.$store.dispatch('frontStream/filterMessages', term);
    },
    handleIntersection(entries) {
      const entry = entries[0];
      if (this.eventInitialized && entry.isIntersecting && !this.isFetching && !this.allMessagesLoaded) {
        this.isFetching = true;
        this.$store.dispatch('frontStream/fetchMessages')
          .then((moreMessagesAvailable) => {
            this.isFetching = false;
            if (!moreMessagesAvailable) {
              this.allMessagesLoaded = true;
              this.observer.disconnect();
            }
          })
          .catch(() => {
            this.isFetching = false;
          });
      }
    },
  },

  computed: {
    ...mapGetters('frontStream', {
      info: 'streamInfo',
      messages: 'liveMessages',
      filters: 'streamFilterFlags',
      eventInitialized: 'eventInitialized',
    }),
  },

  beforeUnmount() {
    if (this.observer) {
      this.observer.disconnect();
    }
  },

  components: {
    GuestBubble,
    HostBubble,
    // Match,
    // LoadMore,
  },

}
</script>

<style scoped>
.feed__main {
  max-height: none;
  height: 100px;
  flex: 1;
}

.feed__actions {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px 20px;
  background: #e3e4e6;
}

.feed__actions button {
  margin-right: 10px;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
}

.feed__actions button:last-child {
  margin-right: 0;
}

.feed__actions svg {
  margin-right: 5px;
  width: 17px;
  height: 17px;
  fill: var(--dark-blue);
}

.feed__actions span {
  vertical-align: top;
  text-decoration: underline;
  font: bold 1.4rem/1.7rem 'Ubuntu', sans-serif;
  color: var(--dark-blue);
}

.feed__empty {
  max-width: 600px;
  margin: 20px auto;
  text-align: center;
}

.feed__empty h3 {
  margin-bottom: 20px;
  font-size: 1.8rem;
  line-height: 2rem;
}

.feed__empty p {
  font-size: 1.4rem;
  line-height: 1.7rem;
}

.feed__empty p:not(:last-child) {
  margin-bottom: 10px;
}
</style>
