import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
import 'firebase/compat/functions';

// const firebaseConfig = {
//   apiKey: "AIzaSyCZmj5jntOnAtrxXjGHXzNbSUKSUHKJzn0",
//   authDomain: "livecaster-dev.firebaseapp.com",
//   databaseURL: "https://livecaster-dev.firebaseio.com",
//   projectId: "livecaster-dev",
//   storageBucket: "livecaster-dev.appspot.com",
//   messagingSenderId: "354735514438",
//   appId: "1:354735514438:web:7519c27187c6ff98ee7b47"
// };

const firebaseConfig = {
  apiKey: "AIzaSyCmuf50OhT1BOQG1zwaAAQ9aUIWfnMMuHU",
  authDomain: "livecaster-io.firebaseapp.com",
  databaseURL: "https://livecaster-io-default-rtdb.europe-west1.firebasedatabase.app",
  projectId: "livecaster-io",
  storageBucket: "livecaster-io.appspot.com",
  messagingSenderId: "737553560403",
  appId: "1:737553560403:web:f49ef205f23c968421e7a6"
};


const app = firebase.initializeApp(firebaseConfig);
const auth = firebase.auth();
const db = firebase.firestore();
const functions = firebase.functions();

export { firebase, app, auth, db, functions };
