<template>
  <div class="bubble bubble--user">
    <div class="bubble__icon" :class="userColor">{{ userInitials }}</div>
    <div class="bubble__core bubble__core--user">

      <div class="bubble__info">
        <div class="bubble__name">
          {{ userName }}
        </div>
        <time class="bubble__time">{{ messageTime }}</time>
      </div>

      <div class="bubble__message bubble__message--user"
        :class="{ 'bubble__message--highlight': msg.highlight && !msg.reply }">
        <MessageBubble v-if="hasMessage" :msg="msg.message" :isGuest="msg.guest" />
        <svg class="bubble__highlight" v-if="msg.highlight">
          <use xlink:href="/assets/img/sprite.svg#highlight-filled"></use>
        </svg>
      </div>

      <div v-if="msg.reply" class="bubble__message bubble__message--reply">
        <MessageBubble :msg="msg.reply" />
      </div>

      <slot></slot>

    </div>
  </div>
</template>

<script>
import moment from 'moment'
import MessageBubble from './content/MessageBubble'

export default {

  props: ['msg'],

  computed: {
    userColor() {
      let turquoise = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h'];
      let pink = ['i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q'];
      let orange = ['r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z'];
      let initial = this.msg.user.substring(0, 1).toLowerCase();
      let color = 'blue';
      if (turquoise.indexOf(initial) > -1) {
        color = 'turquoise';
      }
      if (pink.indexOf(initial) > -1) {
        color = 'pink';
      }
      if (orange.indexOf(initial) > -1) {
        color = 'orange';
      }
      return color;
    },

    userInitials() {
      return this.msg.user ? this.msg.user.trim().substring(0, 2).toLocaleUpperCase() : '';
    },

    userName() {
      return this.msg.user;
    },

    messageTime() {
      if (this.msg.created) {
        return moment(this.msg.created.toDate()).format('HH:mm');
      } else {
        return 'just now';
      }
    },

    hasMessage() {
      return this.msg.message.length;
    },
  },

  components: {
    MessageBubble,
  },

}
</script>
