<template>
  <section class="auth__content-wrap">
    <div class="auth__content">
      <h1>Forgot password?</h1>
      <p>
        Enter your email that you used with your registration,
        and we will send you instructions on how to reset your password.
      </p>
      <form class="auth__form" @submit.prevent="resetPassword()">

        <FormEmail :val="email" :validation="validation.email" @update_val="update_email" />

        <span v-if="errors.length" class="error-message">{{ errors }}</span>
        <input class="form__submit" type="submit" value="RECOVER PASSWORD" />
      </form>
    </div>
  </section>
</template>
  
<script>

import { auth } from '@/firebase';
import FormEmail from '@/components/forms/FormEmail.vue'

export default {

  components: {
    FormEmail
  },

  data() {
    return {
      email: '',
      errors: '',
      validation: {
        email: null,
      },
    }
  },

  methods: {

    async resetPassword() {
      this.errors = '';
      try {
        await auth.sendPasswordResetEmail(this.email);
        // Display a success message or redirect to a password reset confirmation page.
        // You can add your desired logic here.
        console.log('Password reset email sent successfully.');
        this.errors = "Password reset email sent successfully.";
      } catch (error) {
        console.error('Password reset failed:', error);
        this.errors = "We couldn't find an account with this email.";
        // Handle the error and provide user feedback.
      }
    },

    update_email: function (val) {
      this.email = val;
    },
  }
}
</script>

<style scoped>
h1 {
  display: block;
  margin-bottom: 20px;
  font: 300 3.2rem/3.5rem 'Ubuntu';
  color: var(--gray);
}

h1+p {
  margin-bottom: 20px;
  font: 400 1.4rem/2rem 'Ubuntu';
  color: var(--gray);
}
</style>